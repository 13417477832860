import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  Paper, 
  Container, 
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useDropzone } from 'react-dropzone';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { 
  collection, 
  doc, 
  query, 
  where, 
  getDocs,
  getDoc,
  updateDoc 
} from 'firebase/firestore';
import { storage, db } from '../firebase';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import { useAuth } from '../contexts/AuthContext';
import { useNavigationWarning } from '../hooks/useNavigationWarning';
import { Dialog, DialogActions, DialogContent, DialogTitle, Collapse } from '@mui/material';


function EditEvent() {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [newBanner, setNewBanner] = useState(null);
  const [bannerPreview, setBannerPreview] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    startDate: '',
    endDate: '',
    time: '', // Added time field
    organizer: '', // Added organizer field
    isMultiDay: false,
    place: '',
    price: '',
    tickets: '',
    description: '',
    imageUrl: ''
  });

  const [ticketTypes, setTicketTypes] = useState([
    { name: '', price: '', quantity: '', status: 'Available' }
  ]);

  const { currentUser } = useAuth();
  // const [showWarning, setShowWarning] = useState(false);
  const [showExitDialog, setShowExitDialog] = useState(false);
  const [navigationPath, setNavigationPath] = useState('');
  

  const handleExitConfirm = useCallback(() => {
    setShowExitDialog(false);
    if (navigationPath) {
      navigate(navigationPath);
    }
  }, [navigationPath, navigate]);

  
  const [paymentDetails, setPaymentDetails] = useState({
    accountNumber: '',
    accountHolderName: '',
    ifscCode: '',
    bankName: '',
    upiId: ''
  });
  const [isDirty, setIsDirty] = useState(false);

  const { showWarning: navigationWarning, handleNavigationAttempt, confirmNavigation, cancelNavigation } 
    = useNavigationWarning(isDirty);


    const handleTicketTypeChange = (index, field, value) => {
      const newTicketTypes = [...ticketTypes];
      newTicketTypes[index][field] = value;
      setTicketTypes(newTicketTypes);
      setIsDirty(true);
    };
  
    const addTicketType = () => {
      setTicketTypes([...ticketTypes, { name: '', price: '', quantity: '', status: 'Available' }]);
      setIsDirty(true);
    };
  
    const removeTicketType = (index) => {
      if (ticketTypes.length > 1) {
        const newTicketTypes = ticketTypes.filter((_, i) => i !== index);
        setTicketTypes(newTicketTypes);
        setIsDirty(true);
      }
    };


  const formatDateForDisplay = (date) => {
    if (!date) return '';
    return dayjs(date).format('DD/MM/YYYY');
  };

  const formatDateForInput = (date) => {
    if (!date) return '';
    return dayjs(date).format('YYYY-MM-DD');
  };


  useEffect(() => {
    const fetchEvent = async () => {
      setLoading(true);
      try {
        const eventsRef = collection(db, 'events');
        const q = query(eventsRef, where("slug", "==", slug));
        const querySnapshot = await getDocs(q);
        
        if (!querySnapshot.empty) {
          const eventDoc = querySnapshot.docs[0];
          const eventData = eventDoc.data();

          if (eventData.paymentDetails) {
            setPaymentDetails(eventData.paymentDetails);
          }

          if (eventData.ticketTypes && eventData.ticketTypes.length > 0) {
            setTicketTypes(eventData.ticketTypes);
          }

          setFormData({
            name: eventData.name || '',
            startDate: formatDateForInput(eventData.startDate),
            endDate: eventData.endDate ? formatDateForInput(eventData.endDate) : '',
            time: eventData.time || '', // Load time
            organizer: eventData.organizer || '', // Load organizer
            isMultiDay: Boolean(eventData.isMultiDay),
            place: eventData.place || '',
            price: eventData.price || '',
            tickets: eventData.tickets || '',
            description: eventData.description || '',
            imageUrl: eventData.imageUrl || ''
          });
          
          setBannerPreview(eventData.imageUrl || null);
        }
      } catch (error) {
        console.error("Error fetching event:", error);
        alert("Failed to load event details");
      } finally {
        setLoading(false);
      }
    };
    
    if (slug) fetchEvent();
  }, [slug]);


    // Add handler for payment details
    const handlePaymentDetailsChange = (e) => {
      const { name, value } = e.target;
      setPaymentDetails(prev => ({
        ...prev,
        [name]: value
      }));
      setIsDirty(true);
    };

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      setNewBanner(file);
      setBannerPreview(URL.createObjectURL(file));
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'image/*': [] },
    multiple: false
  });

  // Modified handleChange to track form changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
    setIsDirty(true);
  };


  const LoadingOverlay = () => (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9999,
      }}
    >
      <CircularProgress color="primary" />
    </Box>
  );


  const renderTicketTypes = () => (
    <Box sx={{ mt: 3, mb: 2 }}>
      <Typography variant="h6" gutterBottom>
        Ticket Types
      </Typography>
      {ticketTypes.map((ticket, index) => (
        <Box key={index} sx={{ mb: 2, p: 2, border: '1px solid #ddd', borderRadius: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Ticket Name"
                value={ticket.name}
                onChange={(e) => handleTicketTypeChange(index, 'name', e.target.value)}
                placeholder="Early Bird/Regular/VIP"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Price"
                type="number"
                value={ticket.price}
                onChange={(e) => handleTicketTypeChange(index, 'price', e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Quantity"
                type="number"
                value={ticket.quantity}
                onChange={(e) => handleTicketTypeChange(index, 'quantity', e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  value={ticket.status}
                  onChange={(e) => handleTicketTypeChange(index, 'status', e.target.value)}
                  label="Status"
                >
                  <MenuItem value="Available">Available</MenuItem>
                  <MenuItem value="Coming Soon">Coming Soon</MenuItem>
                  <MenuItem value="Sold Out">Sold Out</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {ticketTypes.length > 1 && (
            <IconButton 
              onClick={() => removeTicketType(index)}
              color="error"
              sx={{ mt: 1 }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ))}
      <Button 
        onClick={addTicketType}
        startIcon={<AddIcon />}
        variant="outlined"
        sx={{ mt: 1 }}
      >
        Add Ticket Type
      </Button>
    </Box>
  );



  const PaymentDetailsSection = () => (
    <Collapse in={parseFloat(formData.price) > 0}>
      <Box sx={{ mt: 3, mb: 2 }}>
        <Typography variant="h6" gutterBottom>
          Payment Details
        </Typography>
        <TextField
          name="accountHolderName"
          label="Account Holder Name"
          value={paymentDetails.accountHolderName}
          onChange={handlePaymentDetailsChange}
          fullWidth
          margin="normal"
          required={parseFloat(formData.price) > 0}
        />
        <TextField
          name="accountNumber"
          label="Account Number"
          value={paymentDetails.accountNumber}
          onChange={handlePaymentDetailsChange}
          fullWidth
          margin="normal"
          required={parseFloat(formData.price) > 0}
          type="number"
        />
        <TextField
          name="ifscCode"
          label="IFSC Code"
          value={paymentDetails.ifscCode}
          onChange={handlePaymentDetailsChange}
          fullWidth
          margin="normal"
          required={parseFloat(formData.price) > 0}
        />
        <TextField
          name="bankName"
          label="Bank Name"
          value={paymentDetails.bankName}
          onChange={handlePaymentDetailsChange}
          fullWidth
          margin="normal"
          required={parseFloat(formData.price) > 0}
        />
        <TextField
          name="upiId"
          label="UPI ID (Optional)"
          value={paymentDetails.upiId}
          onChange={handlePaymentDetailsChange}
          fullWidth
          margin="normal"
        />
      </Box>
    </Collapse>
  );


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      const adminDocRef = doc(db, 'admins', currentUser.uid);
      const adminDocSnap = await getDoc(adminDocRef);
      
      if (!adminDocSnap.exists()) {
        alert('You do not have administrator permissions.');
        setLoading(false);
        return;
      }

      let imageUrl = formData.imageUrl;
      if (newBanner) {
        const uniqueFileName = `${uuidv4()}_${newBanner.name}`;
        const imageRef = ref(storage, `images/${uniqueFileName}`);
        await uploadBytes(imageRef, newBanner);
        imageUrl = await getDownloadURL(imageRef);
      }

      const eventsRef = collection(db, 'events');
      const q = query(eventsRef, where("slug", "==", slug));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const eventDoc = querySnapshot.docs[0];
        
        await updateDoc(doc(db, 'events', eventDoc.id), {
          ...formData,
          imageUrl,
          ticketTypes, // Add ticket types to the update
          paymentDetails: parseFloat(formData.price) > 0 ? paymentDetails : null,
          updatedAt: new Date().toISOString()
        });
        
        setIsDirty(false);
        alert('Event updated successfully!');
        navigate('/admin-dashboard');
      }
    } catch (error) {
      console.error("Error updating event:", error);
      alert('Failed to update event. Please try again.');
    } finally {
      setLoading(false);
    }
  };



  if (loading) {
    return (
      <Container maxWidth="md">
        <Box sx={{ textAlign: 'center' }}>
          <Typography>Loading Event details...</Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">


      {/* Exit confirmation dialog */}
        <Dialog open={showExitDialog} onClose={() => setShowExitDialog(false)}>
          <DialogTitle>Unsaved Changes</DialogTitle>
          <DialogContent>
            You have unsaved changes. Are you sure you want to leave?
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowExitDialog(false)}>Keep Editing</Button>
            <Button onClick={handleExitConfirm} color="error">
              Discard Changes
            </Button>
          </DialogActions>
        </Dialog>


      {loading && <LoadingOverlay />}
      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Typography variant="h4" gutterBottom>Edit Event</Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            name="name"
            label="Event Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            name="startDate"
            label="Start Date"
            type="date"
            value={formData.startDate}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            required
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.isMultiDay}
                onChange={handleChange}
                name="isMultiDay"
              />
            }
            label="Multi-day event"
          />
          {formData.isMultiDay && (
            <TextField
              fullWidth
              margin="normal"
              name="endDate"
              label="End Date"
              type="date"
              value={formData.endDate}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              required={formData.isMultiDay}
              inputProps={{ min: formData.startDate }}
            />
          )}
          <TextField
            fullWidth
            margin="normal"
            name="place"
            label="Event Place"
            value={formData.place}
            onChange={handleChange}
            required
          />

          <TextField
            fullWidth
            margin="normal"
            name="time"
            label="Event Time"
            type="time"
            value={formData.time}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            required
          />

          <TextField
            fullWidth
            margin="normal"
            name="description"
            label="Event Description"
            multiline
            rows={4}
            value={formData.description}
            onChange={handleChange}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            name="price"
            label="Price"
            type="number"
            value={formData.price}
            onChange={handleChange}
            required
          />

    <PaymentDetailsSection />

          <TextField
            fullWidth
            margin="normal"
            name="tickets"
            label="Number of Tickets"
            type="number"
            value={formData.tickets}
            onChange={handleChange}
            required
          />

        {renderTicketTypes()}

          {/* Banner Upload Section */}
          <Box sx={{ mt: 4, mb: 4 }}>
            <Typography variant="h6" gutterBottom>Event Banner</Typography>
            <Box {...getRootProps()} sx={{
              border: '2px dashed',
              borderColor: isDragActive ? 'primary.main' : 'grey.300',
              borderRadius: 2,
              p: 2,
              cursor: 'pointer',
              transition: 'all 0.2s ease-in-out',
              '&:hover': {
                borderColor: 'primary.main',
                bgcolor: 'rgba(0, 0, 0, 0.02)'
              },
              position: 'relative',
              minHeight: '200px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column'
            }}>
              <input {...getInputProps()} />
              {bannerPreview ? (
                <Box sx={{ position: 'relative', width: '100%' }}>
                  <img
                    src={bannerPreview}
                    alt="Event Banner"
                    style={{
                      width: '100%',
                      height: 'auto',
                      maxHeight: '300px',
                      objectFit: 'cover',
                      borderRadius: '8px'
                    }}
                  />
                  <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    opacity: 0,
                    transition: 'opacity 0.2s',
                    '&:hover': {
                      opacity: 1
                    },
                    borderRadius: '8px'
                  }}>
                    <Typography color="white">
                      Click or drag to change banner
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <Box sx={{ textAlign: 'center' }}>
                  <Typography>
                    {isDragActive ? 'Drop the banner here' : 'Drag & drop a banner here or click to select'}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>

          <TextField
            fullWidth
            margin="normal"
            name="organizer"
            label="Event Organizer"
            value={formData.organizer}
            onChange={handleChange}
            required
          />

         <Button 
            type="submit"
            variant="contained" 
            fullWidth
            sx={{ 
              mt: 3,
              backgroundColor: 'red',
              borderRadius: 20, // Make button capsule shaped
              '&:hover': {
                backgroundColor: 'darkred'
              }
            }}
          >
            Save Changes
          </Button>
        </form>
      </Paper>
    </Container>
  );
}

export default EditEvent;