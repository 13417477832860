import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import Layout from './components/Layout';
import AppRoutes from './components/AppRoutes';
import { getRedirectResult } from 'firebase/auth';
import { auth, db } from './firebase'; 
import { doc, getDoc, setDoc } from 'firebase/firestore'; 
import { GoogleOAuthProvider } from '@react-oauth/google';

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    const handleRedirectResult = async () => {
      try {
        const result = await getRedirectResult(auth);
        if (result) {
          const user = result.user;
          const userRef = doc(db, 'users', user.uid);
          const userSnap = await getDoc(userRef);
          
          if (userSnap.exists()) {
            navigate('/home');
          } else {
            await setDoc(userRef, {
              email: user.email,
              displayName: user.displayName,
            });
            navigate('/register');
          }
        }
      } catch (error) {
        console.error("Error handling redirect result:", error);
      }
    };

    handleRedirectResult();
  }, [navigate]);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <MuiThemeProvider theme={theme}>
        <StyledThemeProvider theme={theme}>
          <CssBaseline />
          <AuthProvider>
            <Layout>
              <AppRoutes />
            </Layout>
          </AuthProvider>
        </StyledThemeProvider>
      </MuiThemeProvider>
    </GoogleOAuthProvider>
  );
}

export default App;



