import React from 'react';
import { Link } from 'react-router-dom';
import { 
  Grid, 
  Paper, 
  Box, 
  Typography, 
  Button 
} from '@mui/material';

// Assuming formatDisplayDate is defined elsewhere
// const formatDisplayDate = (date) => { ... };

const EventGrid = ({ events, handleDelete, formatDisplayDate }) => {
  return (
    <Grid container spacing={2}>
      {events.map(event => (
        <Grid item xs={12} sm={6} md={4} key={event.id}>
          <Paper sx={{ p: 2, mb: 2, position: 'relative' }}>
            {event.imageUrl ? (
              <Box sx={{ mb: 2, width: '100%', height: '200px' }}>
                <img 
                  src={event.imageUrl} 
                  alt="Event Banner" 
                  style={{ 
                    width: '100%', 
                    height: '100%', 
                    objectFit: 'cover',
                    borderRadius: '8px'
                  }} 
                />
              </Box>
            ) : (
              <Box 
                sx={{ 
                  mb: 2, 
                  width: '100%', 
                  height: '200px', 
                  bgcolor: 'grey.200',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '8px'
                }}
              >
                <Typography color="text.secondary">No Banner</Typography>
              </Box>
            )}
            
            <Typography variant="h6">{event.name}</Typography>
            <Typography>
              Date: {formatDisplayDate(event.startDate)}
              {event.isMultiDay && (
                <span> to {formatDisplayDate(event.endDate)}</span>
              )}
            </Typography>
            <Typography>Place: {event.place}</Typography>
            <Typography>Registrations: {event.registrations || 0}</Typography>
            
            <Box sx={{ mt: 2 }}>
              <Button 
                component={Link} 
                to={`/edit-event/${event.slug}`} 
                variant="contained" 
                sx={{ mr: 1 }}
              >
                Edit
              </Button>
              <Button 
                onClick={() => handleDelete(event.id)} 
                variant="contained" 
                color="error"
              >
                Delete
              </Button>
            </Box>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default EventGrid;