import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import { Container, Typography, TextField, Button } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

function Register() {
  console.log("Register component rendered");
  const [formData, setFormData] = useState({
    name: '',
    college: '',
    grade: '',
    phone: '',
  });
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handlePhoneChange = (value) => {
    setFormData(prev => ({
      ...prev,
      phone: value
    }));
  };

  useEffect(() => {
    console.log("Register component mounted");
    // console.log("Current user:", currentUser);
    
    if (!currentUser) {
      console.log("No current user, redirecting to login");
      navigate('/login');
    }
    return () => {
      console.log("Register component unmounted");
    };
  }, [currentUser, navigate]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await setDoc(doc(db, 'users', currentUser.uid), {
        ...formData,
        email: currentUser.email,
      }, { merge: true });
      
      // Navigate back to the originally intended URL
      const returnUrl = location.state?.returnUrl || '/home';
      navigate(returnUrl);
    } catch (error) {
      console.error("Error saving user data: ", error);
    }
  };


  if (!currentUser) {
    console.log("Rendering loading state");
    return <div>Loading...</div>;
  }

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
        Complete Your Profile
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          margin="normal"
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <TextField
          fullWidth
          margin="normal"
          label="College"
          name="college"
          value={formData.college}
          onChange={handleChange}
          required
        />
        {/* <TextField
          fullWidth
          margin="normal"
          label="Grade"
          name="grade"
          value={formData.grade}
          onChange={handleChange}
          required
        /> */}
        {/* <Box sx={{ width: '90%', margin: '0 auto' }}> */}
          <PhoneInput
            country={'in'}
            value={formData.phone}
            onChange={handlePhoneChange}
            inputProps={{
              required: true,
              name: 'phone'
            }}
            containerStyle={{
              width: '100%',
              margin: '16px auto',
            }}
            inputStyle={{
              width: '100%',
              height: '56px',
              borderRadius: '4px',
              fontSize: '16px',
              padding: '14px 14px 14px 58px', // Increased left padding
            }}
            buttonStyle={{
              borderRadius: '4px 0 0 4px',
              padding: '0 0 0 8px', // Added padding to fix flag position
              border: '1px solid rgba(0, 0, 0, 0.23)',
            }}
          />
        {/* </Box> */}

        <Button type="submit" variant="contained" color="primary" fullWidth>
          Complete Registration!
        </Button>
      </form>
    </Container>
  );
}

export default Register;


