import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  deleteDoc, 
  doc, 
  getDoc,
  updateDoc     // Changed from setDoc
} from 'firebase/firestore';
import { auth, db } from '../firebase';
import { 
  Typography, 
  Box, 
  Paper, 
  Button, 
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import dayjs from 'dayjs'; // Add this import
import { PaymentDetailsSection } from './PaymentDetailsSection';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import EventGrid from './EventGrid';

// import { doc, getDoc } from 'firebase/firestore';

const formatDisplayDate = (date) => {
  if (!date) return '';
  return dayjs(date).format('MMMM D, YYYY');
};

function AdminDashboard() {
  const [adminName, setAdminName] = useState('');
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [profileData, setProfileData] = useState({
    name: '',
    email: '',
    phone: ''
  });
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();


  useEffect(() => {
    const fetchAdminDetails = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const adminDoc = await getDoc(doc(db, 'admins', user.uid));
          if (adminDoc.exists()) {
            const data = adminDoc.data();
            setAdminName(data.name);
            setProfileData({
              name: data.name,
              email: data.email,
              phone: data.phone
            });
          }
        }
      } catch (error) {
        console.error("Error fetching admin details:", error);
      }
    };

    fetchAdminDetails();
  }, []);

  const handleProfileUpdate = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        await updateDoc(doc(db, 'admins', user.uid), {
          ...profileData
        }, { merge: true });
        setAdminName(profileData.name);
        setShowEditProfile(false);
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      alert('Failed to update profile');
    }
  };

    useEffect(() => {
      const fetchEvents = async () => {
        setLoading(true);
        try {
          const user = auth.currentUser;
          if (user) {
            const adminDocRef = doc(db, 'admins', user.uid);
            const adminDoc = await getDoc(adminDocRef);
            if (!adminDoc.exists()) {
              throw new Error("User is not an admin");
            }
  
            const eventsRef = collection(db, 'events');
            const q = query(eventsRef, where("adminId", "==", user.uid));
            const querySnapshot = await getDocs(q);
            const eventList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setEvents(eventList);
          } else {
            throw new Error("No authenticated user found");
          }
        } catch (error) {
          console.error("Error fetching events: ", error);
          setError(error.message);
          navigate('/');
        } finally {
          setLoading(false);
        }
      };
  
      fetchEvents();
    }, [navigate]);

  const handleDelete = async (eventId) => {
    if (window.confirm("Are you sure you want to delete this event?")) {
      try {
        await deleteDoc(doc(db, 'events', eventId));
        setEvents(events.filter(event => event.id !== eventId));
        alert("Event deleted successfully");
      } catch (error) {
        console.error("Error deleting event: ", error);
        alert("Failed to delete event. Please try again.");
      }
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  if (events.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: 'calc(100vh - 200px)',
          textAlign: 'center',
          p: 3
        }}
      >
        <Typography 
          variant="h4" 
          gutterBottom 
          sx={{ 
            fontWeight: 'bold',
            mb: 3
          }}
        >
          Nothing to see here 🧐
        </Typography>
        
        <Typography 
          variant="h6" 
          gutterBottom 
          sx={{ 
            color: 'text.secondary',
            mb: 4
          }}
        >
          Host your first event now!
        </Typography>

        <Button
          component={Link}
          to="/register-event"
          variant="contained"
          size="large"
          startIcon={<AddCircleOutlineIcon />}
          sx={{
            borderRadius: '50px',
            padding: '12px 30px',
            fontSize: '1.2rem',
            textTransform: 'none',
            background: 'linear-gradient(45deg, #FF0000 30%, #FF4646 90%)',
            boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
            '&:hover': {
              background: 'linear-gradient(45deg, #FF4646 30%, #FF0000 90%)',
              transform: 'scale(1.05)',
              transition: 'transform 0.2s'
            }
          }}
        >
          HOST NOW!
        </Button>
      </Box>
    );
  }

  return (
      <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Typography variant="h4">
          Admin Dashboard {adminName && `for ${adminName}`}
        </Typography>
        <IconButton onClick={() => setShowEditProfile(true)} color="primary">
          <EditIcon />
        </IconButton>
      </Box>

      {/* Edit Profile Dialog */}
      <Dialog open={showEditProfile} onClose={() => setShowEditProfile(false)}>
        <DialogTitle>Edit Profile</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            value={profileData.name}
            onChange={(e) => setProfileData(prev => ({ ...prev, name: e.target.value }))}
          />
          <TextField
            margin="dense"
            label="Email"
            fullWidth
            value={profileData.email}
            onChange={(e) => setProfileData(prev => ({ ...prev, email: e.target.value }))}
          />
          <TextField
            margin="dense"
            label="Phone"
            fullWidth
            value={profileData.phone}
            onChange={(e) => setProfileData(prev => ({ ...prev, phone: e.target.value }))}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowEditProfile(false)}>Cancel</Button>
          <Button onClick={handleProfileUpdate}>Save Changes</Button>
        </DialogActions>
      </Dialog>
        
        <PaymentDetailsSection />

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h5">Events created by you...</Typography>
          <Link
            to="/register-event"
            style={{ 
              color: '#1976d2', // Material-UI primary color
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            +NEW
          </Link>
        </Box>
        
        <EventGrid 
            events={events} 
            handleDelete={handleDelete}
            formatDisplayDate={formatDisplayDate}
          />

        {/* <Grid container spacing={2}>
          {events.map(event => (
            <Grid item xs={12} sm={6} md={4} key={event.id}>
              <Paper sx={{ p: 2, mb: 2, position: 'relative' }}>
              {event.imageUrl && (
                <Box sx={{ mb: 2, width: '100%', height: '200px' }}>
                  <img 
                    src={event.imageUrl} 
                    alt="Event Banner" 
                    style={{ 
                      width: '100%', 
                      height: '100%', 
                      objectFit: 'cover',
                      borderRadius: '8px'
                    }} 
                  />
                </Box>
              ) : (
                <Box sx={{ 
                  mb: 2, 
                  width: '100%', 
                  height: '200px', 
                  bgcolor: 'grey.200',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '8px'
                }}>
                  <Typography color="text.secondary">No Banner</Typography>
                </Box>
              )}
                <Typography variant="h6">{event.name}</Typography>
                <Typography>
                  Date: {formatDisplayDate(event.startDate)}
                  {event.isMultiDay && (
                    <span> to {formatDisplayDate(event.endDate)}</span>
                  )}
                </Typography>
                <Typography>Place: {event.place}</Typography>
                <Typography>Registrations: {event.registrations || 0}</Typography>
                <Box sx={{ mt: 2 }}>
                  <Button component={Link} to={`/edit-event/${event.slug}`} variant="contained" sx={{ mr: 1 }}>
                    Edit
                  </Button>
                  <Button onClick={() => handleDelete(event.id)} variant="contained" color="error">
                    Delete
                  </Button>
                </Box>
              </Paper>
            </Grid>
          ))}

        </Grid> */}
      </Box>
    );
  }

export default AdminDashboard;
