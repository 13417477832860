// src/components/PaymentDetailsSection.js
import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Alert
} from '@mui/material';
import { useAdminPaymentDetails } from '../hooks/useAdminPaymentDetails';

export function PaymentDetailsSection() {
  const { paymentDetails, loading, error, savePaymentDetails } = useAdminPaymentDetails();
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    accountNumber: '',
    accountHolderName: '',
    ifscCode: '',
    bankName: '',
    upiId: ''
  });

  const handleEditClick = () => {
    setFormData(paymentDetails || formData);
    setIsEditing(true);
  };

  const handleChange = (e) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleSubmit = async () => {
    const success = await savePaymentDetails(formData);
    if (success) {
      setIsEditing(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Paper sx={{ p: 3, mb: 3 }}>
      <Typography variant="h6" gutterBottom>
        Payment Details
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {!isEditing && (
        <Box>
          {paymentDetails ? (
            <>
              <Typography variant="body1" gutterBottom>
                Account Holder: {paymentDetails.accountHolderName}
              </Typography>
              <Typography variant="body1" gutterBottom>
                Bank: {paymentDetails.bankName}
              </Typography>
              <Typography variant="body1" gutterBottom>
                Account: ****{paymentDetails.accountNumber.slice(-4)}
              </Typography>
              <Typography variant="body1" gutterBottom>
                IFSC: {paymentDetails.ifscCode}
              </Typography>
              {paymentDetails.upiId && (
                <Typography variant="body1" gutterBottom>
                  UPI ID: {paymentDetails.upiId}
                </Typography>
              )}
              <Button 
                variant="contained" 
                onClick={handleEditClick}
                sx={{ mt: 2 }}
              >
                Edit Details
              </Button>
            </>
          ) : (
            <Box>
              <Typography color="text.secondary" gutterBottom>
                No payment details saved yet.
              </Typography>
              <Button 
                variant="contained" 
                onClick={handleEditClick}
                sx={{ mt: 1 }}
              >
                Add Payment Details
              </Button>
            </Box>
          )}
        </Box>
      )}

      <Dialog open={isEditing} onClose={() => setIsEditing(false)} maxWidth="sm" fullWidth>
        <DialogTitle>
          {paymentDetails ? 'Edit Payment Details' : 'Add Payment Details'}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 1 }}>
            <TextField
              fullWidth
              margin="normal"
              label="Account Holder Name"
              name="accountHolderName"
              value={formData.accountHolderName}
              onChange={handleChange}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              label="Account Number"
              name="accountNumber"
              value={formData.accountNumber}
              onChange={handleChange}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              label="IFSC Code"
              name="ifscCode"
              value={formData.ifscCode}
              onChange={handleChange}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              label="Bank Name"
              name="bankName"
              value={formData.bankName}
              onChange={handleChange}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              label="UPI ID (Optional)"
              name="upiId"
              value={formData.upiId}
              onChange={handleChange}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsEditing(false)}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}