import React, { useState, useEffect } from 'react';
import { Box, Typography, Container, Link } from '@mui/material';
import Slider from 'react-slick';
import { Link as RouterLink } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { styled } from '@mui/material/styles';
import  funkyBGImage    from '../assets/images/funky-bg-2.jpg';
import { collection, query, getDocs } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust this import based on your file structure
import { Timestamp } from 'firebase/firestore';
import TornBG from '../assets/images/dark_bg.jpg';
import funkyBG from '../assets/images/funky-5.jpg';

const EventsSection = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  padding: '0 20px 60px', // Remove top padding
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start', // Change from center to flex-start
  background: 'linear-gradient(45deg, #FF6B6B, #4ECDC4, #45B7D1)',
  marginTop: '-64px', // Adjust this value to match your navbar height
  paddingTop: '64px', // Add padding top to offset the negative margin
}));

const EventSlide = styled(Box)(({ theme }) => ({
  padding: '0 10px', // Add spacing between slides
}));

const EventImage = styled('img')({
  width: '100%',
  height: '500px', // Reduce height
  objectFit: 'cover',
  borderRadius: '15px', // Add rounded corners
});

const EventsTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "'Arial Black', Gadget, sans-serif", // You can change this to your preferred font
  fontWeight: 'bold',
  color: 'white',
  fontSize: '30px',
  textAlign: 'left',
  marginBottom: '15px',
  marginTop: '20px', // Add some top margin
}));

const EventName = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  bottom: '20px',
  left: '20px',
  color: 'white',
  backgroundColor: 'rgba(0,0,0,0.5)',
  padding: '10px',
  borderRadius: '5px',
}));

const FunkyMessage = styled(Box)(({ theme }) => ({
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundImage: `url(${funkyBGImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}));

const FunkyText = styled(Typography)(({ theme }) => ({
  fontFamily: "'Super-Funky', sans-serif",
  fontSize: '2.5rem',
  color: 'black',
  textAlign: 'center',
  padding: '20px',
  transform: 'rotate(-5deg)',
  textShadow: '2px 2px 0 #ff00de, 4px 4px 0 #00ff00, 6px 6px 0 #0000ff',
  '& .warning': {
    color: '#FFD700', // Gold color for WARNING
    display: 'block', // Makes it a block element, forcing a line break
    marginBottom: '10px', // Adds space below WARNING
    fontSize: '2.3rem',
  }
}));


const Footer = styled(Box)(({ theme }) => ({
  minHeight: '50vh',
  backgroundColor: 'black',
  // backgroundImage: `url(${TornBG})`,
  color: 'white',
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center', // This centers the content horizontally
}));

const FooterLink = styled(Link)(({ theme }) => ({
  color: 'white',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const HighlightedWord = styled(RouterLink)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  textDecoration: 'none',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
}));



function Home() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventsRef = collection(db, 'events');
        const q = query(eventsRef);
        const querySnapshot = await getDocs(q);
        
        const currentDate = new Date();
        
        const eventList = querySnapshot.docs
          .map(doc => {
            const data = doc.data();
            // Handle different date formats
            const startDate = typeof data.startDate === 'string' 
              ? new Date(data.startDate.split('/').reverse().join('-'))
              : data.startDate instanceof Timestamp 
                ? data.startDate.toDate() 
                : new Date(data.startDate);

            const endDate = data.endDate
              ? (typeof data.endDate === 'string'
                ? new Date(data.endDate.split('/').reverse().join('-'))
                : data.endDate instanceof Timestamp 
                  ? data.endDate.toDate() 
                  : new Date(data.endDate))
              : null;
            
            return {
              id: doc.id,
              ...data,
              startDate,
              endDate
            };
          })
          .filter(event => {
            const endDate = event.isMultiDay ? event.endDate : event.startDate;
            return endDate >= currentDate;
          })
          .sort((a, b) => a.startDate - b.startDate);
        
        console.log("Available events:", eventList);
        setEvents(eventList);
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchEvents();
  }, []);


  // Different slider settings based on number of events
  const sliderSettings = {
    dots: events.length > 1,
    infinite: events.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: events.length > 1,
    autoplaySpeed: 3000,
    arrows: events.length > 1,
    adaptiveHeight: true
  };



  const funkyMessages = [
    {
      warning: "🚨 WARNING 🚨",
      message: "THESE EVENTS MAY CAUSE EXTREME HAPPINESS AND FOMO!"
    },
    {
      warning: "🎉 ATTENTION 🎉",
      message: "BORING COLLEGE LIFE? WE'VE GOT THE CURE!"
    },
    {
      warning: "🔥 ALERT 🔥",
      message: "MEMORIES IN THE MAKING, DON'T MISS OUT!"
    },
    {
      warning: "⚡ HEADS UP ⚡",
      message: "YOUR SOCIAL LIFE IS ABOUT TO GET LIT!"
    },
    {
      warning: "🌟 NOTICE 🌟",
      message: "STUDYING TOO MUCH? TIME FOR A LEGENDARY BREAK!"
    }
  ];

  // Randomly select a funky message
  const randomMessage = funkyMessages[Math.floor(Math.random() * funkyMessages.length)];

  

  return (
    <Box>

      <EventsSection>
        <EventsTitle variant="h3">
          Crazy Live Events...
        </EventsTitle>
        
        {loading ? (
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            height: '500px'
          }}>
            <Typography variant="h5" color="white">Loading amazing events...</Typography>
          </Box>
        ) : events.length > 0 ? (
          events.length === 1 ? (
            // Single event display
            <EventSlide 
              component={RouterLink} 
              to={`/event-details/${events[0].slug}`}
              sx={{ cursor: 'pointer' }}
            >
              <Box sx={{ position: 'relative' }}>
                <EventImage src={events[0].imageUrl} alt={events[0].name} />
                <EventName variant="h4">{events[0].name}</EventName>
              </Box>
            </EventSlide>
          ) : (
            // Multiple events slider
            <Slider {...sliderSettings}>
              {events.map((event) => (
                <EventSlide 
                  key={event.id} 
                  component={RouterLink} 
                  to={`/event-details/${event.slug}`}
                >
                  <Box sx={{ position: 'relative' }}>
                    <EventImage src={event.imageUrl} alt={event.name} />
                    <EventName variant="h4">{event.name}</EventName>
                  </Box>
                </EventSlide>
              ))}
            </Slider>
          )
        ) : (
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            height: '500px'
          }}>
            <Typography variant="h5" color="white">
              New events coming soon! Stay tuned! 🎉
            </Typography>
          </Box>
        )}
      </EventsSection>

      {/* <FunkyMessage>
        <FunkyText>
          <span className="warning">🚨WARNING🚨</span>
          THESE EVENTS MAY CAUSE EXTREME HAPPINESS AND FOMO!
        </FunkyText>
      </FunkyMessage> */}

    <FunkyMessage>
        <FunkyText>
          <span className="warning">{randomMessage.warning}</span>
          {randomMessage.message}
        </FunkyText>
    </FunkyMessage>


      <Footer>
          <Typography variant="h6" sx={{ transform: 'rotate(-3deg)', marginBottom: '20px' }}>
            Wanna <HighlightedWord to="/register-event">host</HighlightedWord> an event?🪩
          </Typography>
          <Typography variant="h6" sx={{ transform: 'rotate(2deg)', marginBottom: '20px' }}>
            <HighlightedWord  to="/dashboard">See</HighlightedWord> how you look👀
          </Typography>
          <Typography variant="h6" sx={{ transform: 'rotate(-2deg)', marginBottom: '20px' }}>
            <HighlightedWord to="/events">Explore</HighlightedWord> awesome events!🌍
          </Typography>
          <Typography variant="h6" sx={{ transform: 'rotate(3deg)', marginBottom: '20px' }}>
            <HighlightedWord to="/contact">Connect</HighlightedWord> with us!🤝
          </Typography>
      </Footer>
    </Box>
  );
}

export default Home;



