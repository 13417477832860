import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { 
  Box, 
  Container,
  Typography,
  Button,
  Paper,
  Grid,
  IconButton,
  Collapse,
  Alert,
  Snackbar,
  Card,
  CardContent,
  useTheme,
  useMediaQuery,
  styled
} from '@mui/material';
import {
  CalendarToday,
  AccessTime,
  LocationOn,
  Person,
  Share,
  ExpandMore,
  ExpandLess
} from '@mui/icons-material';
import { useAuth } from '../contexts/AuthContext';
import Confetti from 'react-confetti';

// Styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  borderRadius: theme.spacing(2)
}));

const IconText = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1)
}));

const FixedBottomBar = styled(Paper)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  padding: theme.spacing(2),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  zIndex: 1000,
  borderRadius: 0,
  boxShadow: '0px -2px 8px rgba(0,0,0,0.1)'
}));

function EventDetails() {
  // States
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  // Hooks
  const { identifier } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [searchParams] = useSearchParams();

  // Date formatting helper
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const getOrdinalSuffix = (day) => {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    };

    const day = date.getDate();
    const suffix = getOrdinalSuffix(day);
    return date.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    }).replace(/\d+/, day + suffix);
  };

  // Check for payment success
  useEffect(() => {
    const status = searchParams.get('status');
    if (status === 'success') {
      setShowConfetti(true);
      setShowSuccessMessage(true);
      
      // Remove success parameters from URL
      const newUrl = window.location.pathname;
      window.history.pushState({}, '', newUrl);

      // Stop confetti after 5 seconds
      setTimeout(() => {
        setShowConfetti(false);
      }, 5000);
    }
  }, [searchParams]);

  // Fetch event data
  useEffect(() => {
    const fetchEvent = async () => {
      if (!identifier) {
        setLoading(false);
        return;
      }

      try {
        const eventsRef = collection(db, 'events');
        const q = query(eventsRef, where('slug', '==', identifier));
        let eventDoc = await getDocs(q);

        if (!eventDoc.empty) {
          const doc = eventDoc.docs[0];
          const eventData = doc.data();
          
          setEvent({
            id: doc.id,
            ...eventData,
            title: eventData.name,
            imageUrl: eventData.imageUrl,
            date: formatDate(eventData.startDate),
            time: eventData.time,
            location: eventData.place,
            organizer: eventData.organizer,
            description: eventData.description,
            price: eventData.price,
            paymentLink: eventData.paymentLink, // Make sure this is included
            ticketTypes: [
              {
                name: "Early Bird",
                price: `₹${eventData.price}`,
                status: eventData.tickets > 0 ? "Available" : "Sold Out"
              }
            ]
          });
        } else {
          console.log("Event not found by slug");
          setEvent(null);
        }
      } catch (error) {
        console.error("Error fetching event:", error);
        setEvent(null);
      } finally {
        setLoading(false);
      }
    };

    fetchEvent();
  }, [identifier]);

  // Event handlers
  // const handleBuyTickets = () => {
  //   if (!currentUser) {
  //     navigate('/login', { state: { from: `/event-details/${identifier}` } });
  //     return;
  //   }
    
  //   if (event.paymentLink) {
  //     const returnUrl = `${window.location.origin}/event-details/${identifier}?status=success`;
  //     const paymentUrl = `${event.paymentLink}&redirect_url=${encodeURIComponent(returnUrl)}`;
  //     window.location.href = paymentUrl;
  //   }
  // };

  
  const handleBuyTickets = () => {
    if (!currentUser) {
      navigate('/login', { state: { from: `/event-details/${identifier}` } });
      return;
    }
    
    if (event.paymentLink) {
      // Just go to the payment page directly
      window.location.href = `https://rzp.io/rzp/SNEAK-IN`;
    }
  };

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: event.title,
          text: event.description,
          url: window.location.href
        });
      } else {
        await navigator.clipboard.writeText(window.location.href);
        alert('Link copied to clipboard!');
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  // Loading and error states
  if (loading) {
    return <Box sx={{ p: 4, textAlign: 'center' }}>Loading...</Box>;
  }

  if (!event) {
    return <Box sx={{ p: 4, textAlign: 'center' }}>Event not found</Box>;
  }
      

  const DesktopLayout = () => (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Grid container spacing={4}>
        {/* Left Column - Event Image */}
        <Grid item xs={12} md={7}>
          <Box sx={{ position: 'sticky', top: 24 }}>
            <img
              src={event.imageUrl}
              alt={event.title}
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: theme.spacing(2),
                maxHeight: '600px',
                objectFit: 'cover'
              }}
            />
          </Box>
        </Grid>

        {/* Right Column - Event Details */}
        <Grid item xs={12} md={5}>
          <StyledPaper elevation={1}>
            <Typography variant="h4" gutterBottom fontWeight="bold">
              {event.title}
            </Typography>

            <Box sx={{ mb: 3 }}>
              <IconText>
                <CalendarToday color="primary" />
                <Typography>{event.date}</Typography>
              </IconText>
              <IconText>
                <AccessTime color="primary" />
                <Typography>{event.time}</Typography>
              </IconText>
              <IconText>
                <LocationOn color="primary" />
                <Typography>{event.location}</Typography>
              </IconText>
              <IconText>
                <Person color="primary" />
                <Typography>{event.organizer}</Typography>
              </IconText>
            </Box>

            <Button
              startIcon={<Share />}
              color="primary"
              sx={{ mb: 2 }}
            >
              Share Event
            </Button>
          </StyledPaper>

          <StyledPaper elevation={1}>
            <Typography variant="h5" gutterBottom fontWeight="bold">
              About Event
            </Typography>
            <Typography
              variant="body1"
              sx={{ whiteSpace: 'pre-line' }}
            >
              {event.description}
            </Typography>
          </StyledPaper>

          <StyledPaper elevation={1}>
            <Typography variant="h5" gutterBottom fontWeight="bold">
              Select Tickets
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {event.ticketTypes?.map((ticket, index) => (
                <Card key={index} variant="outlined">
                  <CardContent sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    '&:last-child': { pb: 2 }
                  }}>
                    <Box>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {ticket.name}
                      </Typography>
                      <Typography color="primary" variant="h6">
                        {ticket.price}
                      </Typography>
                    </Box>
                    <Button
                      variant={ticket.status === 'Available' ? 'contained' : 'outlined'}
                      disabled={ticket.status !== 'Available'}
                      sx={{ borderRadius: 5 }}
                    >
                      {ticket.status}
                    </Button>


                    <Button
                      variant="contained"
                      onClick={handleBuyTickets}
                      sx={{
                        borderRadius: 5,
                        px: 4,
                        bgcolor: 'red',
                        '&:hover': {
                          bgcolor: '#d32f2f'
                        }
                      }}
                    >
                      Buy Tickets
                    </Button>


                  </CardContent>
                </Card>
              ))}
            </Box>
          </StyledPaper>
        </Grid>
      </Grid>
    </Container>
  );

  const MobileLayout = () => (
    <Box>
      <img
        src={event.imageUrl}
        alt={event.title}
        style={{
          width: '100%',
          height: 'auto',
          objectFit: 'cover'
        }}
      />

      <Box sx={{ p: 2, pb: 10 }}>

      <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'flex-start',
          mb: 2 
        }}>

        <Typography variant="h5" gutterBottom fontWeight="bold">
          {event.title}
        </Typography>
        <IconButton onClick={handleShare} sx={{ mt: -1 }}>
            <Share color="primary" />
          </IconButton>
        </Box>

        <Box sx={{ mb: 3 }}>
          <IconText>
            <CalendarToday color="primary" fontSize="small" />
            <Typography variant="body2">{event.date}</Typography>
          </IconText>
          <IconText>
            <AccessTime color="primary" fontSize="small" />
            <Typography variant="body2">{event.time}</Typography>
          </IconText>
          <IconText>
            <LocationOn color="primary" fontSize="small" />
            <Typography variant="body2">{event.location}</Typography>
          </IconText>
          <IconText>
            <Person color="primary" fontSize="small" />
            <Typography variant="body2">{event.organizer}</Typography>
          </IconText>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 1
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              About Event
            </Typography>
            <IconButton
              onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)}
              size="small"
            >
              {isDescriptionExpanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
          <Collapse in={isDescriptionExpanded} collapsedSize="80px">
            <Typography
              variant="body2"
              sx={{ whiteSpace: 'pre-line' }}
            >
              {event.description}
            </Typography>
          </Collapse>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {event.ticketTypes?.map((ticket, index) => (
            <Card key={index} variant="outlined">
              <CardContent sx={{ 
                display: 'flex', 
                justifyContent: 'space-between',
                alignItems: 'center',
                '&:last-child': { pb: 2 }
              }}>
                <Box>
                  <Typography variant="subtitle2" fontWeight="bold">
                    {ticket.name}
                  </Typography>
                  <Typography color="primary" variant="subtitle1">
                    {ticket.price}
                  </Typography>
                </Box>
                <Typography
                  variant="body2"
                  color={ticket.status === 'Available' ? 'primary' : 'text.secondary'}
                >
                  {ticket.status}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
      </Box>


      <FixedBottomBar elevation={3}>
        <Box>
          <Typography variant="caption" color="text.secondary">
            Starting from
          </Typography>
          <Typography variant="h6" color="primary" fontWeight="bold">
            {event.ticketTypes?.[0]?.price}
          </Typography>
        </Box>
        <Button
          variant="contained"
          onClick={handleBuyTickets}
          sx={{
            borderRadius: 5,
            px: 4,
            bgcolor: 'red',
            '&:hover': {
              bgcolor: '#d32f2f'
            }
          }}
        >
          Buy Tickets
        </Button>
      </FixedBottomBar>

    </Box>
  );

  // Main render
  return (
    <>
      {/* Confetti Effect */}
      {showConfetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={false}
          numberOfPieces={200}
          gravity={0.3}
        />
      )}

      {/* Success Message */}
      <Snackbar
        open={showSuccessMessage}
        autoHideDuration={6000}
        onClose={() => setShowSuccessMessage(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setShowSuccessMessage(false)} 
          severity="success"
          sx={{ width: '100%' }}
        >
          {`You've successfully registered for ${event.title} 🎉! You'll receive a confirmation email shortly.`}
        </Alert>
      </Snackbar>

      {/* Layout Render */}
      {isMobile ? <MobileLayout /> : <DesktopLayout />}
    </>
  );
}

export default EventDetails;
