// import React, { useState, useEffect } from 'react';
// import { createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
// import { setDoc, doc, getDoc } from 'firebase/firestore';
// import { auth, db } from '../firebase';
// import { TextField, Button, Box, Typography, Link } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import zxcvbn from 'zxcvbn';
// import PhoneInput from 'react-phone-input-2';
// import 'react-phone-input-2/lib/style.css';

// function AdminAuth() {
//   const [isLogin, setIsLogin] = useState(true);
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [name, setName] = useState('');
//   const [phone, setPhone] = useState('');
//   const navigate = useNavigate();
//   const [passwordStrength, setPasswordStrength] = useState(0);


//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, async (user) => {
//       if (user) {
//         const adminRef = doc(db, 'admins', user.uid);
//         const adminSnap = await getDoc(adminRef);
//         if (adminSnap.exists()) { // Add isLogin check
//           navigate('/admin-dashboard');
//         }
//       }
//     });
  
//     return () => unsubscribe();
//   }, [navigate]);


// // In AdminAuth.js, update the handleSubmit function

// const handleSubmit = async (e) => {
//   e.preventDefault();
//   try {
//     if (isLogin) {
//       const userCredential = await signInWithEmailAndPassword(auth, email, password);
//       const adminDoc = await getDoc(doc(db, 'admins', userCredential.user.uid));
      
//       if (adminDoc.exists()) {
//         navigate('/admin-dashboard', { replace: true });
//       } else {
//         throw new Error('Not authorized as admin');
//       }
//     } else {
//       // Handle registration
//       const userCredential = await createUserWithEmailAndPassword(auth, email, password);
//       await setDoc(doc(db, 'admins', userCredential.user.uid), {
//         name,
//         email,
//         phone,
//         isAdmin: true,
//         createdAt: new Date().toISOString()
//       });
//       navigate('/admin-dashboard', { replace: true });
//     }
//   } catch (error) {
//     console.error("Error:", error);
//     alert(error.message);
//   }
// };


//   // In AdminAuth.js
// useEffect(() => {
//   const checkAuth = async () => {
//     const user = auth.currentUser;
//     if (user) {
//       const adminRef = doc(db, 'admins', user.uid);
//       const adminSnap = await getDoc(adminRef);
//       if (adminSnap.exists()) {
//         navigate('/admin-dashboard', { replace: true });
//       }
//     }
//   };

//   checkAuth();
// }, [navigate]);



//   const checkPasswordStrength = (password) => {
//     const result = zxcvbn(password);
//     setPasswordStrength(result.score);
//   };

//   // const handleSubmit = async (e) => {
//   //   e.preventDefault();
//   //   try {
//   //     if (isLogin) {
//   //       await signInWithEmailAndPassword(auth, email, password);
//   //       navigate('/admin-dashboard');
//   //     } else {
//   //       const userCredential = await createUserWithEmailAndPassword(auth, email, password);
//   //       await setDoc(doc(db, 'admins', userCredential.user.uid), {
//   //         name,
//   //         email,
//   //         phone,
//   //         isAdmin: true,
//   //       });
//   //       navigate('/register-event');
//   //     }
//   //   } catch (error) {
//   //     console.error("Error:", error);
//   //     alert(error.message);
//   //   }
//   // };

//   return (
//     <Box sx={{ maxWidth: 400, margin: 'auto', mt: 4, textAlign: 'center' }}>
//       <Typography variant="h5" gutterBottom>
//         {isLogin ? 'Admin Login' : 'Admin Registration'}
//       </Typography>
//       <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
//         {!isLogin && (
//           <TextField
//             margin="normal"
//             required
//             fullWidth
//             label="Full Name"
//             value={name}
//             onChange={(e) => setName(e.target.value)}
//             sx={{ width: '90%' }}
//           />
//         )}
//         <TextField
//           margin="normal"
//           required
//           fullWidth
//           label="Email Address"
//           type="email"
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//           sx={{ width: '90%' }}
//         />

//         <TextField
//           margin="normal"
//           required
//           fullWidth
//           label="Password"
//           type="password"
//           value={password}
//           onChange={(e) => {
//             setPassword(e.target.value);
//             if (!isLogin) {
//               checkPasswordStrength(e.target.value);
//             }
//           }}
//           sx={{ width: '90%' }}
//         />
        
//         {!isLogin && (
//           <Typography color={passwordStrength > 2 ? 'green' : 'red'}>
//             Password strength: {passwordStrength}/4
//           </Typography>
//         )}


//         {!isLogin && (
//           <PhoneInput
//             country={'in'}
//             value={phone}
//             onChange={setPhone}
//             inputProps={{
//               required: true,
//             }}
//             containerStyle={{ width: '90%', margin: '16px auto' }}
//           />
//         )}
//         <Button type="submit" fullWidth variant="contained" sx={{ width: '90%', mt: 3, mb: 2 }}>
//           {isLogin ? 'Login' : 'Register'}
//         </Button>
//       </Box>
//       <Typography>
//         {isLogin ? "New user? " : "Already have an account? "}
//         <Link component="button" variant="body2" onClick={() => setIsLogin(!isLogin)}>
//           {isLogin ? 'Register' : 'Login'}
//         </Link>
//       </Typography>
//     </Box>
//   );
// }

// export default AdminAuth;



import React, { useState, useEffect } from 'react';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { 
  setDoc, 
  doc, 
  getDoc, 
  collection, 
  getDocs 
} from 'firebase/firestore';
import { auth, db } from '../firebase';
import { TextField, Button, Box, Typography, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import zxcvbn from 'zxcvbn';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';


function AdminAuth() {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const navigate = useNavigate();

  // Check if user is already logged in as admin
  useEffect(() => {
    const checkAdmin = async () => {
      const user = auth.currentUser;
      if (user) {
        const adminDoc = await getDoc(doc(db, 'admins', user.uid));
        if (adminDoc.exists()) {
          navigate('/admin-dashboard');
        }
      }
    };
    checkAdmin();
  }, [navigate]);


  const checkPasswordStrength = (password) => {
    const result = zxcvbn(password);
    setPasswordStrength(result.score);
  };


// In AdminAuth.js, update the handleSubmit function

const handleSubmit = async (e) => {
  e.preventDefault();
  setLoading(true);

  try {
    if (isLogin) {
      // Login logic remains the same
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const adminDoc = await getDoc(doc(db, 'admins', userCredential.user.uid));
      
      if (!adminDoc.exists()) {
        throw new Error('Not authorized as admin');
      }
      
      navigate('/admin-dashboard', { replace: true });
    } else {
      // Registration - New Method
      try {
        // First, create the user in Firebase Auth
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        
        // Then create the admin document
        await setDoc(doc(db, 'admins', userCredential.user.uid), {
          name,
          email,
          phone,
          isAdmin: true,
          createdAt: new Date().toISOString(),
        });

        console.log('Admin created successfully');
        navigate('/admin-dashboard', { replace: true });
      } catch (error) {
        // If the error is email-already-in-use, show proper message
        if (error.code === 'auth/email-already-in-use') {
          alert('This email is already registered. Please try logging in or use a different email.');
        } else {
          throw error; // Rethrow other errors
        }
      }
    }
  } catch (error) {
    console.error('Auth error:', error);
    let errorMessage = '';
    
    switch (error.code) {
      case 'auth/email-already-in-use':
        errorMessage = 'This email is already registered';
        break;
      case 'auth/invalid-email':
        errorMessage = 'Invalid email address';
        break;
      case 'auth/wrong-password':
        errorMessage = 'Incorrect password';
        break;
      case 'auth/user-not-found':
        errorMessage = 'No admin account found with this email';
        break;
      case 'auth/weak-password':
        errorMessage = 'Password should be at least 6 characters';
        break;
      default:
        errorMessage = error.message;
    }
    
    alert(errorMessage);
  } finally {
    setLoading(false);
  }
};


useEffect(() => {
  // Add this to check admin collection on component mount
  const checkExistingAdmin = async () => {
    try {
      const adminsRef = collection(db, 'admins');
      const querySnapshot = await getDocs(adminsRef);
      console.log('Existing admins:', querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    } catch (error) {
      console.error('Error checking admins:', error);
    }
  };

  checkExistingAdmin();
}, []);


  return (
    <Box sx={{ maxWidth: 400, margin: 'auto', mt: 4, textAlign: 'center' }}>
      <Typography variant="h5" gutterBottom>
        {isLogin ? 'Admin Login' : 'Admin Registration'}
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
        {!isLogin && (
          <TextField
            margin="normal"
            required
            fullWidth
            label="Full Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            disabled={loading}
            sx={{ width: '90%' }}
          />
        )}
        <TextField
          margin="normal"
          required
          fullWidth
          label="Email Address"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={loading}
          sx={{ width: '90%' }}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          label="Password"
          type="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            if (!isLogin) {
              checkPasswordStrength(e.target.value);
            }
          }}
          disabled={loading}
          sx={{ width: '90%' }}
        />
        {!isLogin && (
          <>
            <Typography color={passwordStrength > 2 ? 'green' : 'red'}>
              Password strength: {passwordStrength}/4
            </Typography>
            <PhoneInput
              country={'in'}
              value={phone}
              onChange={setPhone}
              disabled={loading}
              inputProps={{
                required: true,
              }}
              containerStyle={{ width: '90%', margin: '16px auto' }}
            />
          </>
        )}
        <Button 
          type="submit" 
          fullWidth 
          variant="contained" 
          disabled={loading}
          sx={{ width: '90%', mt: 3, mb: 2 }}
        >
          {loading ? 'Processing...' : isLogin ? 'Login' : 'Register'}
        </Button>
      </Box>
      <Typography>
        {isLogin ? "New admin? " : "Already have an account? "}
        <Link 
          component="button" 
          variant="body2" 
          onClick={() => setIsLogin(!isLogin)}
          disabled={loading}
        >
          {isLogin ? 'Register' : 'Login'}
        </Link>
      </Typography>
    </Box>
  );
}

export default AdminAuth;