import React from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom'; // Add useLocation here
import { useAuth } from '../contexts/AuthContext';
import LandingPage from './LandingPage';
import Home from './Home';
import Login from './Login';
import Register from './Register';
import EventDetails from './EventDetails';
import UserDashboard from './UserDashboard';
import AdminDashboard from './AdminDashboard';
import RegisterEvent from './RegisterEvent';
import EventList from './EventList';
import EditEvent from './EditEvent';
import AdminAuth from './AdminAuth';
import PrivacyPolicy from './PrivacyPolicy';
import { PaymentDetailsSection } from './PaymentDetailsSection';




function ProtectedRoute({ children }) {
  const { currentUser, loading } = useAuth();
  const location = useLocation(); // Add this

  if (loading) return <div>Loading...</div>;
  if (!currentUser) return <Navigate to="/login" state={{ from: location }} />;
  return children;
}

function AdminProtectedRoute({ children }) {
  const { currentUser, loading } = useAuth();
  const location = useLocation(); // Add this
  
  if (loading) return <div>Loading...</div>;
  
  if (!currentUser) {
    return <Navigate to="/admin-auth" state={{ from: location }} />;
  }
  
  if (!currentUser.isAdmin) {
    return <Navigate to="/" />;
  }
  
  return children;
}


function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/events" element={<EventList />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/event-details/:identifier" element={<EventDetails />} />
      <Route path="/admin-auth" element={<AdminAuth />} />

      <Route path="/home" element={
        <ProtectedRoute>
          <Home />
        </ProtectedRoute>
      } />
      <Route path="/dashboard" element={
        <ProtectedRoute>
          <UserDashboard />
        </ProtectedRoute>
      } />

      {/* Protected Admin Routes */}
      <Route path="/register-event" element={
        <AdminProtectedRoute>
          <RegisterEvent />
        </AdminProtectedRoute>
      } />
      <Route path="/admin-dashboard" element={
        <AdminProtectedRoute>
          <AdminDashboard />
        </AdminProtectedRoute>
      } />
      <Route path="/edit-event/:slug" element={
        <AdminProtectedRoute>
          <EditEvent />
        </AdminProtectedRoute>
      } />

    </Routes>
  );
}

export default AppRoutes;