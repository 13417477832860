import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Typography, Container, Button } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import googleLogo from '../assets/images/google-logo.png';

const Login = () => {
  const { signIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleGoogleLogin = async () => {
    try {
      const isRegistered = await signIn();
      const returnUrl = location.state?.from || '/home';
      
      if (!isRegistered) {
        navigate('/register', { state: { returnUrl } });
      } else {
        navigate(returnUrl);
      }
    } catch (error) {
      console.error('Error during Google sign-in:', error);
      if (error.code !== 'auth/cancelled-popup-request') {
        alert('Sign-in failed. Please try again.');
      }
    }
  };
  
  return (
    <Container maxWidth="xs" sx={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Sign Up/Login
        </Typography>
        <Typography variant="body1" gutterBottom>
          & experience college events <br />
          like never before!
        </Typography>
      </Box>
      <Button
        onClick={handleGoogleLogin}
        variant="contained"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '50px',
          backgroundColor: 'white',
          color: 'black',
          textTransform: 'none',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
          '&:hover': {
            backgroundColor: '#f5f5f5',
          },
          width: '100%',
          py: 1.5,
        }}
      >
        <img src={googleLogo} alt="Google logo" style={{ width: 24, height: 24, marginRight: 8 }} />
        Continue with Google
      </Button>
    </Container>
  );
};

export default Login;