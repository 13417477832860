import styled from 'styled-components';
import { Box, Typography, Card, CardMedia } from '@mui/material'
import last_torn_bg from '../assets/images/last_torn_bg.png'

export const PageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background-image: url('/path-to-your-background-image.jpg');
  background-size: cover;
  background-position: center;
`;

export const Navbar = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: #FF0000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  z-index: 1000;
`;

export const Logo = styled.img`
  height: 40px;
`;

export const NavLinks = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const NavLink = styled.a`
  color: white;
  text-decoration: none;
`;

export const MainContent = styled.div`
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  height: calc(100vh - 60px);
`;

export const Heading = styled.h1`
  font-size: 3rem;
  color: black;
  text-align: center;
  color: white,

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

export const DynamicText = styled.span`
  color: white;

`;

export const ExploreButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  background-color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
`;

export const EventsSection = styled.div`
  background-color: #333;
  padding: 20px;
`;

export const EventsHeading = styled.h2`
  color: yellow;
  font-size: 2rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const EventSlider = styled.div`
  display: flex;
  overflow-x: auto;
  gap: 20px;
  padding: 20px 0;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

// export const EventCard = styled.div`
//   width: 200px;
//   height: 300px;
//   flex-shrink: 0;
//   background-color: ${props => props.color};

//   @media (max-width: 768px) {
//     width: 150px;
//     height: 225px;
//   }

//   @media (max-width: 480px) {
//     width: 120px;
//     height: 180px;
//   }
// `;

export const MenuIcon = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const MobileMenu = styled.div`
  display: none;
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  background-color: #FF0000;
  padding: 20px;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    display: ${props => props.isOpen ? 'flex' : 'none'};
  }
`;

export const Footer = styled(Box) ( ({ theme}) => ({

      [theme.breakpoints.down('sm')]: {
        backgroundImage: `url(${last_torn_bg})`,
      },
      [theme.breakpoints.up('md')]: {
        background: 'black',
      }, 

    backgroundImage: `url(${last_torn_bg})`,
    color: 'white',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '64px',
  }));

  export const EventCard = styled(Card)( ({ theme }) => ({
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
        height:'600px',
        width: '100%',
      },
    height: '600px',
    width: '120%',
    overflow: 'hidden',
    borderRadius: '4px',
    border: '2px solid white',
  }));
  
  export const EventImage = styled(CardMedia)({
    height: '100%',
  });
  
  export const EventName = styled(Typography)({
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    background: 'rgba(0, 0, 0, 0.7)',
    color: 'white',
    padding: '8px',
  });