// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase'; 
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { 
  GoogleAuthProvider, 
  signInWithPopup, 
  signInWithRedirect,
  getRedirectResult,
  getAuth,
  onAuthStateChanged, 
  signOut 
} from 'firebase/auth';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isRegistrationComplete, setIsRegistrationComplete] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      try {
        if (user) {
          // Check admin status
          const adminDoc = await getDoc(doc(db, 'admins', user.uid));
          const isAdmin = adminDoc.exists();

          // Check if user has completed registration
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          setIsRegistrationComplete(userDoc.exists());

          setCurrentUser({
            ...user,
            isAdmin,
            ...(isAdmin ? adminDoc.data() : {})
          });

          console.log('User auth state:', { uid: user.uid, isAdmin, isRegistered: userDoc.exists() });
        } else {
          setCurrentUser(null);
          setIsRegistrationComplete(false);
        }
      } catch (error) {
        console.error('Auth state change error:', error);
        setCurrentUser(null);
        setIsRegistrationComplete(false);
      } finally {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  // const signIn = async () => {
  //   try {
  //     setIsProcessing(true);
  //     const provider = new GoogleAuthProvider();
      
  //     provider.setCustomParameters({
  //       prompt: 'select_account',
  //       // Remove display and redirect_uri from here
  //     });

  //     let result;
  //     if (/iPhone|iPad|iPod/.test(navigator.userAgent)) {
  //       // Use signInWithRedirect for iOS devices
  //       const auth = getAuth();
  //       await signInWithRedirect(auth, provider);
  //       result = await getRedirectResult(auth);
  //     } else {
  //       // Use popup for other devices
  //       result = await signInWithPopup(auth, provider);
  //     }
  
  //     if (!result) {
  //       throw new Error('Sign in failed - no result');
  //     }


  //     // const result = await signInWithPopup(auth, provider);
  //     const user = result.user;
  //     const userRef = doc(db, 'users', user.uid);
  //     const userSnap = await getDoc(userRef);


  //     if (!userSnap.exists()) {
  //       await setDoc(userRef, {
  //         email: user.email,
  //         displayName: user.displayName,
  //         photoURL: user.photoURL,
  //         registeredEvents: []
  //       });
  //       setIsRegistrationComplete(false);
  //       return false;
  //     }

  //     setIsRegistrationComplete(true);
  //     localStorage.setItem('user', JSON.stringify(user));
  //     return true;

  //   } catch (error) {
  //     console.error('Sign in error:', error);
  //     if (error.code === 'auth/popup-blocked') {
  //       // Try redirect method if popup is blocked
  //       const auth = getAuth();
  //       const provider = new GoogleAuthProvider();
  //       await signInWithRedirect(auth, provider);
  //       return false;
  //     }
  //     throw error;
  //   } finally {
  //     setIsProcessing(false);
  //   }
  // };


  // AuthContext.js

// In AuthContext.js - Update only the signIn function


// AuthContext.js - Update the signIn function only
// AuthContext.js - Simplified signIn function
const signIn = async () => {
  try {
    setIsProcessing(true);
    const provider = new GoogleAuthProvider();
    
    provider.setCustomParameters({
      prompt: 'select_account'
    });

    const result = await signInWithPopup(auth, provider);

    if (!result || !result.user) {
      return false;
    }

    const user = result.user;
    const userRef = doc(db, 'users', user.uid);
    const userSnap = await getDoc(userRef);

    if (!userSnap.exists()) {
      await setDoc(userRef, {
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL,
        registeredEvents: []
      });
      setIsRegistrationComplete(false);
      return false;
    }

    setIsRegistrationComplete(true);
    localStorage.setItem('user', JSON.stringify(user));
    return true;

  } catch (error) {
    console.error('Sign in error:', error);
    if (error.code === 'auth/popup-blocked') {
      const provider = new GoogleAuthProvider();
      await signInWithRedirect(auth, provider);
    }
    throw error;
  } finally {
    setIsProcessing(false);
  }
};

// Remove the extra useEffect for iOS handling


// Add this useEffect in AuthContext to handle iOS redirect result
// useEffect(() => {
//   const handleRedirectResult = async () => {
//     try {
//       const auth = getAuth();
//       const result = await getRedirectResult(auth);
      
//       if (result) {
//         const user = result.user;
//         const userRef = doc(db, 'users', user.uid);
//         const userSnap = await getDoc(userRef);
        
//         // Get stored return URL
//         const pendingReturnUrl = window.sessionStorage.getItem('pendingReturnUrl');
//         window.sessionStorage.removeItem('pendingReturnUrl'); // Clean up

//         if (!userSnap.exists()) {
//           await setDoc(userRef, {
//             email: user.email,
//             displayName: user.displayName,
//             photoURL: user.photoURL,
//             registeredEvents: []
//           });
//           navigate('/register', { state: { returnUrl: pendingReturnUrl || '/home' } });
//         } else {
//           navigate(pendingReturnUrl || '/home');
//         }
//       }
//     } catch (error) {
//       console.error('Redirect result error:', error);
//     }
//   };

//   if (/iPhone|iPad|iPod/.test(navigator.userAgent)) {
//     handleRedirectResult();
//   }
// }, [navigate]);



  const logout = async () => {
    try {
      await signOut(auth);
      setCurrentUser(null);
      setIsRegistrationComplete(false);
      localStorage.removeItem('user');
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const refreshUserSession = async () => {
    if (currentUser) {
      try {
        await currentUser.getIdToken(true);
        console.log("User session refreshed");
      } catch (error) {
        console.error("Error refreshing user session:", error);
      }
    }
  };

  const value = {
    currentUser,
    loading,
    signIn,
    logout,
    refreshUserSession,
    isRegistrationComplete,
    isProcessing
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}