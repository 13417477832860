import React from 'react';
import { Container, Typography, Box, Link } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  fontSize: '1.5rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.25rem',
  },
}));

const LastUpdated = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(3),
  fontSize: '0.9rem',
}));

const ListItem = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  marginBottom: theme.spacing(1),
  position: 'relative',
  '&:before': {
    content: '"•"',
    position: 'absolute',
    left: theme.spacing(-2),
  },
  fontSize: '1rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.9rem',
  },
}));

const PrivacyPolicy = () => {
  return (
    <StyledContainer maxWidth="md">
      <Typography variant="h3" component="h1" gutterBottom sx={{
        fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
        fontWeight: 700,
        textAlign: 'center',
        marginBottom: 4
      }}>
        Privacy Policy
      </Typography>

      <LastUpdated>
        <strong>Last Updated:</strong> {new Date().toLocaleDateString()}
      </LastUpdated>

      <Typography paragraph sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>
        Welcome to SNEAK IN, a platform for colleges to host and market events. Your privacy is important to us, and we are committed to protecting your information. Here's a quick overview of our privacy practices:
      </Typography>

      <SectionTitle variant="h5">1. Information We Collect</SectionTitle>
      <Typography paragraph sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>
        When you use SNEAK IN, we collect basic information through Google login, including your:
      </Typography>
      <Box sx={{ ml: 2, mb: 2 }}>
        <ListItem>Email address</ListItem>
        <ListItem>Profile photo associated with your Google account</ListItem>
        <ListItem>Publicly available information on Google</ListItem>
      </Box>

      <SectionTitle variant="h5">2. How We Use Your Information</SectionTitle>
      <Typography paragraph sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>
        We use this information to:
      </Typography>
      <Box sx={{ ml: 2, mb: 2 }}>
        <ListItem>Facilitate login and create a personalized user experience</ListItem>
        <ListItem>Provide access to features of the SNEAK IN platform</ListItem>
      </Box>

      <SectionTitle variant="h5">3. Data Security</SectionTitle>
      <Typography paragraph sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>
        We prioritize the security of your data and use industry-standard practices to protect it. However, please remember that no online service is completely secure.
      </Typography>

      <SectionTitle variant="h5">4. Data Sharing</SectionTitle>
      <Typography paragraph sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>
        We do not sell, trade, or share your Google account information with third parties. Your data is only used to enhance your experience on the SNEAK IN platform.
      </Typography>

      <SectionTitle variant="h5">5. Third-Party Services</SectionTitle>
      <Typography paragraph sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>
        Our app uses Google services for authentication, and their privacy policy governs your interaction with those services.
      </Typography>

      <SectionTitle variant="h5">6. Changes to This Privacy Policy</SectionTitle>
      <Typography paragraph sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>
        We may update this Privacy Policy as needed. If changes are made, we will update the "Last Updated" date above.
      </Typography>

      <SectionTitle variant="h5">7. Contact Us</SectionTitle>
      <Typography paragraph sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>
        If you have any questions or concerns about our privacy practices, please contact us at{' '}
        <Link href="mailto:contact@sneakin.me" color="primary">
          sneakin.hq@gmail.com
        </Link>
      </Typography>
    </StyledContainer>
  );
};

export default PrivacyPolicy;