// src/components/EventList.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Grid, Card, CardContent, CardMedia, Typography, Button } from '@mui/material';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

function EventList() {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      const eventsCollection = collection(db, 'events');
      const eventSnapshot = await getDocs(eventsCollection);
      const eventList = eventSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
    }));
      setEvents(eventList);
    };

    fetchEvents();
  }, []);

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Upcoming Events
      </Typography>
      <Grid container spacing={3}>
        {events.map((event) => (
          <Grid item xs={12} sm={6} md={4} key={event.id}>
            <Card>
            <CardMedia
                component="img"
                sx={{ height: 'auto', maxHeight: 200, objectFit: 'contain' }}
                image={event.imageUrl}
                alt={event.name}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {event.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {event.date} - {event.place}
                </Typography>
                <Button component={Link} to={`/event-details/${event.id}`} variant="contained" sx={{ mt: 2 }}>
                  View Details
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default EventList;