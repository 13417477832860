import React, { useState, useEffect, useRef } from 'react';
import Typed from 'typed.js';
import { Link } from 'react-router-dom';
import { Box, Typography, Container, Button, } from '@mui/material';
import { styled } from '@mui/material/styles';
import { collection, query, limit, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import Slider from "react-slick";
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sneakin_bg from '../assets/images/sneakin_bg.png'
import dark_bg from '../assets/images/dark_bg.jpg'
import mini_logo from '../assets/images/mini_logo.png'
import sneak_in from '../assets/images/sneak-in_new.png'
import {
  Footer,
  EventCard,
  EventImage,
  EventName
} from './LandingPageStyles';
import comicBg from '../assets/images/comic-bg-5.png';

const PageContainer = styled(Box)(({ theme }) => ({
  width: '100vw',
  minHeight: '100vh',
  // backgroundImage: 'url("../assets/images/black_crumpeled_paper.png")',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  marginTop: '-64px',
  paddingTop: '64px',

}));

const MainContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  justifyContent: 'center',
  height: '100vh',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  marginTop: '-64px',
  paddingTop: '64px',
  [theme.breakpoints.down('sm')]: {
    backgroundImage: `url(${sneakin_bg})`,
  },
  [theme.breakpoints.up('md')]: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', // Example gradient
  }, 
}));

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: '5rem',
  paddingLeft: '15px',
  color: 'white',
  textAlign: 'center',
  fontWeight: 'bold',
  marginBottom: '-10px',
  lineHeight: 1.2,
  [theme.breakpoints.down('sm')]: {
    fontSize: '4.2rem',
    textAlign: 'left ',
  },
}));

const DynamicText = styled('span')( ({ theme }) => ({
  color: 'white',
  fontSize: '6rem',
  fontWeight: 'bold',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '4.5rem',
    textAlign: 'left ',
  },
}));

const ExploreButton = styled(Button)(({ theme }) => ({
  marginTop: '40px',
  fontSize: '18px',
  textDecoration: 'none',
  fontWeight: 'bold',
  backgroundColor: 'red',
  color: 'white',
  padding: theme.spacing(1, 2),
  borderRadius: '25px',
  
  '&:hover': {
    backgroundColor: 'white',
    color: 'red',
    border: '2px solid black'
  },
}));


const EventsSection = styled(Box)(({ theme }) => ({
  padding: '40px 20px',
  display: 'flex',
  paddingTop: '40px',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  minHeight: '100vh',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  background: 'black', // Solid background
  margin: 0,
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '4px',
    background: 'linear-gradient(90deg, #FF0000, #FF6B6B)',
  },
  [theme.breakpoints.down('sm')]: {
    backgroundImage: `url(${dark_bg})`,
  },
}));


const EventsHeading = styled(Typography)(({ theme }) => ({
  color: 'white',
  fontWeight: '800',
  fontSize: '40px',
  paddingLeft: '5px',
  marginBottom: '30px',
  position: 'relative',
  textTransform: 'uppercase',
  letterSpacing: '2px',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: -10,
    left: 5,
    width: '60px',
    height: '4px',
    background: 'red',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '32px',
  }
}));


// const FunkySection = styled(Box)(({ theme }) => ({
//   height: '100vh', // Changed to full viewport height
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   // background: 'linear-gradient(45deg, #FF6B6B, #4ECDC4, #45B7D1)',
//   backgroundImage: `url(${comicBg})`,
//   backgroundSize: '400% 400%',
//   animation: 'gradient 15s ease infinite',
//   position: 'relative',
//   overflow: 'hidden',
//   '@keyframes gradient': {
//     '0%': { backgroundPosition: '0% 50%' },
//     '50%': { backgroundPosition: '100% 50%' },
//     '100%': { backgroundPosition: '0% 50%' }
//   }
// }));

// const FunkyContent = styled(Box)(({ theme }) => ({
//   textAlign: 'center',
//   padding: '20px',
//   maxWidth: '90%',
//   animation: 'slideIn 0.5s ease-out',
//   '@keyframes slideIn': {
//     '0%': {
//       opacity: 0,
//       transform: 'translateY(30px)',
//     },
//     '100%': {
//       opacity: 1,
//       transform: 'translateY(0)',
//     },
//   }
// }));

// const WarningText = styled(Typography)(({ theme }) => ({
//   fontFamily: "'Arial Black', Gadget, sans-serif",
//   color: '#FFD700',
//   fontSize: '2.3rem',
//   marginBottom: '10px',
//   textShadow: '2px 2px 0 #000',
//   display: 'block',
//   [theme.breakpoints.down('sm')]: {
//     fontSize: '1.8rem',
//   }
// }));

// const WarningText = styled(Typography)(({ theme }) => ({
//   fontFamily: "'Arial Black', Gadget, sans-serif",
//   fontSize: '3rem',
//   fontWeight: '900',
//   marginBottom: '20px',
//   padding: '10px',
//   display: 'inline-block',
//   position: 'relative',
//   color: '#FFD700',
//   textTransform: 'uppercase',
//   letterSpacing: '3px',
//   animation: 'glow 2s ease-in-out infinite',
//   background: 'linear-gradient(45deg, #FF0000, #FF6B6B)',
//   backgroundSize: '200% 200%',
//   WebkitBackgroundClip: 'text',
//   WebkitTextFillColor: 'transparent',
//   textShadow: `
//     0 0 10px rgba(255,215,0,0.5),
//     0 0 20px rgba(255,215,0,0.3),
//     0 0 30px rgba(255,215,0,0.1)
//   `,
//   '&::before': {
//     content: '""',
//     position: 'absolute',
//     bottom: 0,
//     left: '50%',
//     transform: 'translateX(-50%)',
//     width: '80%',
//     height: '3px',
//     background: 'linear-gradient(90deg, transparent, #FFD700, transparent)',
//   },
//   '@keyframes glow': {
//     '0%, 100%': {
//       textShadow: '0 0 10px rgba(255,215,0,0.5), 0 0 20px rgba(255,215,0,0.3), 0 0 30px rgba(255,215,0,0.1)',
//     },
//     '50%': {
//       textShadow: '0 0 20px rgba(255,215,0,0.8), 0 0 30px rgba(255,215,0,0.6), 0 0 40px rgba(255,215,0,0.4)',
//     }
//   },
//   [theme.breakpoints.down('sm')]: {
//     fontSize: '2rem',
//     letterSpacing: '2px',
//   }
// }));



// const MainText = styled(Typography)(({ theme }) => ({
//   fontFamily: "'Super-Funky', sans-serif",
//   fontSize: '2.5rem',
//   color: 'white',
//   textAlign: 'center',
//   transform: 'rotate(-2deg)',
//   textShadow: '2px 2px 0 #ff00de, 4px 4px 0 #00ff00, 6px 6px 0 #0000ff',
//   [theme.breakpoints.down('sm')]: {
//     fontSize: '1.8rem',
//   }
// }));

// const MainText = styled(Typography)(({ theme }) => ({
//   fontFamily: "'Super-Funky', sans-serif",
//   fontSize: '3.5rem',
//   fontWeight: 'bold',
//   color: 'white',
//   textAlign: 'center',
//   transform: 'rotate(-2deg)',
//   textShadow: '2px 2px 0 #ff00de, 4px 4px 0 #00ff00, 6px 6px 0 #0000ff',
//   transition: 'all 0.3s ease',
//   padding: '20px',
//   animation: 'bounce 1s ease-in-out infinite',
//   '@keyframes bounce': {
//     '0%, 100%': {
//       transform: 'rotate(-2deg) translateY(0)',
//     },
//     '50%': {
//       transform: 'rotate(-2deg) translateY(-10px)',
//     }
//   },
//   '&:hover': {
//     transform: 'rotate(0deg) scale(1.05)',
//   },
//   [theme.breakpoints.down('sm')]: {
//     fontSize: '2.2rem',
//   }
// }));


const FunkySection = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  background: `url(${comicBg})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  margin: 0, // Remove any margin
  padding: '40px 0',
  borderBottom: 'none', // Ensure no border
}));

const FunkyContent = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  padding: '20px',
  maxWidth: '90%',
  zIndex: 2,
  animation: 'fadeIn 0.5s ease-out',
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
      transform: 'translateY(30px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  }
}));

const WarningText = styled(Typography)(({ theme }) => ({
  fontFamily: "'Arial Black', Gadget, sans-serif",
  fontSize: '40px',
  fontWeight: '900',
  marginBottom: '20px',
  padding: '10px',
  display: 'inline-block',
  position: 'relative',
  color: '#FF0000',
  textTransform: 'uppercase',
  letterSpacing: '3px',
  textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
    letterSpacing: '2px',
  }
}));

const MainText = styled(Typography)(({ theme }) => ({
  fontFamily: "'Super-Funky', sans-serif",
  fontSize: '3.5rem',
  fontWeight: 'bold',
  color: '#181C14',
  textAlign: 'center',
  letterSpacing: '2px',
  textShadow: `
    2px 2px 0 #ff00de,
    4px 4px 0 #00ff00,
    6px 6px 0 #0000ff,
    8px 8px 8px rgba(0,0,0,0.5)
  `,
  transform: 'rotate(-2deg)',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.2rem',
    textShadow: `
      2px 2px 0 #ff00de,
      3px 3px 0 #00ff00,
      4px 4px 0 #0000ff,
      5px 5px 4px rgba(0,0,0,0.5)
    `
  }
}));



const funkyMessages = [
  {
    warning: "🎭 CAUTION 🎭",
    message: "YOUR COLLEGE LIFE IS ABOUT TO GET EPIC!"
  },
  {
    warning: "🌟 ALERT 🌟",
    message: "BOREDOM ENDS WHERE SNEAK IN BEGINS!"
  },
  {
    warning: "⚡WARNING⚡",
    message: "SIDE EFFECTS INCLUDE ENDLESS FUN!"
  },
  {
    warning: "🎪ATTENTION🎪",
    message: "YOUR SOCIAL LIFE UPGRADE IS HERE!"
  },
  {
    warning: "🚨BEWARE🚨",
    message: "THIS APP IS HIGHLY ADDICTIVE!"
  },
  {
    warning: "🎯NOTICE🎯",
    message: "FOMO-FREE ZONE AHEAD!"
  },
  {
    warning: "🎪 YO! 🎪",
    message: "TEXTBOOKS CAN WAIT, MEMORIES CAN'T!"
  }
];


function LandingPage() {
  const [events, setEvents] = useState([]);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const typedRef = useRef(null);
  const eventsSectionRef = useRef(null);


  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const q = query(collection(db, 'events'), limit(6));
        const querySnapshot = await getDocs(q);
        const eventList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        console.log("Fetched events:", eventList);
        setEvents(eventList);
      } catch (error) {
        console.error("Error fetching events:", error);
        setEvents([]);
      }
    };
    fetchEvents();
  }, []); // Remove duplicate fetchEvents() call


  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentMessageIndex((prev) => (prev + 1) % funkyMessages.length);
    }, 4000);

    return () => clearInterval(intervalId);
  }, []);


  const SingleEventContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    maxWidth: '300px',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '280px',
    },
  }));
  
  const StyledEventCard = styled(EventCard)(({ theme }) => ({
    margin: '0 auto',
    height: '100%',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  }));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentMessageIndex(prev => 
        prev === funkyMessages.length - 1 ? 0 : prev + 1
      );
    }, 4000);

    return () => clearInterval(intervalId);
  }, []);



          useEffect(() => {
            const options = {
              strings: ['college events...', 'college fests...', 'house parties...', 'concerts...', 'FUNNN!'],
              typeSpeed: 50,
              backSpeed: 50,
              loop: true,
            };

            typedRef.current = new Typed('#dynamic-text', options);

            return () => {
              if (typedRef.current) {
                typedRef.current.destroy();
              }
            };
          }, []);


  const settings = {
    dots: true,
    infinite: events.length > 1,  // Only enable infinite if more than one event
    speed: 500,
    slidesToShow: Math.min(4, events.length), // Show max 4 or total events, whichever is smaller
    slidesToScroll: 1,
    autoplay: events.length > 1,  // Only autoplay if more than one event
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(3, events.length),
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: Math.min(2, events.length),
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


  const renderEvents = () => {
    if (events.length === 0) {
      return <Typography color="white">No events available</Typography>;
    }
  
    if (events.length === 1) {
      return (
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center',
          width: '100%',
          mt: 2 
        }}>
          <Link 
            to={`/event-details/${events[0].slug}`} 
            style={{ 
              textDecoration: 'none',
              maxWidth: '300px',
              width: '100%'
            }}
          >
            <EventCard>
              <EventImage
                image={events[0].imageUrl}
                title={events[0].name}
              />
              <EventName variant="h6">{events[0].name}</EventName>
            </EventCard>
          </Link>
        </Box>
      );
    }


    return (
      <Slider {...settings}>
        {events.map(event => (
          <Link 
            to={`/event-details/${event.slug}`} 
            key={event.id} 
            style={{ textDecoration: 'none' }}
          >
            <EventCard>
              <EventImage
                image={event.imageUrl}
                title={event.name}
              />
              <EventName variant="h6">{event.name}</EventName>
            </EventCard>
          </Link>
        ))}
      </Slider>
    );
  };

  const handleExplore = () => {
    // document.getElementById('events-section').scrollIntoView({ behavior: 'smooth' });
    eventsSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  };


  return (
    <>
    <PageContainer sx={{ 
    maxWidth: '100vw', 
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    padding: 0
  }}>
     <MainContent>
          <Heading style={{ marginBottom: 0, paddingBottom: 0, textAlign: 'left', alignContent: 'left'}}>
            Experience<br />
            the best <br />
            of <br />
            <DynamicText id="dynamic-text"></DynamicText>
          </Heading>
          
          <ExploreButton onClick={handleExplore} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
            explore!
          </ExploreButton>
        </MainContent>

        {/* <FunkySection>
          <FunkyContent>
            <WarningText>
              {funkyMessages[currentMessageIndex].warning}
            </WarningText>
            <MainText key={currentMessageIndex}>
              {funkyMessages[currentMessageIndex].message}
            </MainText>
          </FunkyContent>
        </FunkySection> */}

        
    <FunkySection>
      <FunkyContent>
        <WarningText>
          {funkyMessages[currentMessageIndex].warning}
        </WarningText>
        <MainText key={currentMessageIndex}>
          {funkyMessages[currentMessageIndex].message}
        </MainText>
      </FunkyContent>
    </FunkySection>


    <EventsSection ref={eventsSectionRef}>
    <EventsHeading variant="h4">
      upcoming events...
    </EventsHeading>
    {renderEvents()}
  </EventsSection>
  </PageContainer>



      <Footer>
        <Container maxWidth="lg">
          <Box display="flex" flexDirection="column" alignItems="center">
            <Link to={"/"}> </Link>
            <img src={mini_logo} alt="Sneak In Logo" style={{ width: '80px', marginBottom: '0.5rem' }} />
            {/* <Typography variant="body1" align="center" gutterBottom>
              let's make your boring college life, FUN again!!!
            </Typography> */}
            <Typography variant="body2" align="center" gutterBottom>
              coming soon to your nearest colleges 😉
            </Typography>
            <Box mt={2} align='center'>

            <Link onClick={() => window.open('https://instagram.com/sneak.in_', '_blank')}> 
                  <InstagramIcon fontSize="large" style={{ marginRight: '1rem', textDecoration: 'none', color: 'white' }} />
            </Link>
            <Link onClick={() => window.open('https://x.com/sneak_in_', '_blank')}>
              <TwitterIcon fontSize="large" style={{ marginRight: '1rem', textDecoration: 'none', color: 'white' }} />
            </Link>
            </Box>
            <Typography 
              variant="body2" 
              align="center" 
              color={'gray'}
              gutterBottom
            >
              <Link to={'/privacy-policy'}> Privacy Policy </Link>
            </Typography>

          </Box>
        </Container>
      </Footer>
    </>
  );
}

export default LandingPage;

