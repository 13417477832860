import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemText, Box, Typography, Button, Avatar, TextField, Paper, IconButton } from '@mui/material';
import { Grid, Card, CardMedia, CardContent, CardActionArea } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import SaveIcon from '@mui/icons-material/Save';
import HistoryIcon from '@mui/icons-material/History';
import { useAuth } from '../contexts/AuthContext';
import { auth, db, storage } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const DashboardContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  maxWidth: '100%',
  margin: '0 auto',
}));

const ProfileSection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
}));

const AvatarUpload = styled(Box)(({ theme }) => ({
  position: 'relative',
  marginBottom: theme.spacing(2),
  '&:hover .overlay': {
    opacity: 1,
  },
}));

const AvatarOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  height: '100%',
  width: '100%',
  opacity: 0,
  transition: '.3s ease',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const SimpleButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'white',
  color: '#FF0000',
  border: '2px solid #FF0000',
  borderRadius: '20px',
  padding: '8px 16px',
  '&:hover': {
    backgroundColor: '#FFEEEE',
  },
}));

const EditButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  borderRadius: '20px',
}));

const HistorySection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const ExploreButton = styled(Button)(({ theme }) => ({
  borderRadius: '25px',
  backgroundColor: '#FF6B6B',
  color: 'white',
  '&:hover': {
    backgroundColor: '#FF8E8E',
  },
}));

function UserDashboard() {
  const { currentUser } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [events, setEvents] = useState([]);
  const [userEvents, setUserEvents] = useState([]);
  const navigate = useNavigate();


  useEffect(() => {
    const fetchUserProfile = async () => {
      if (currentUser) {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserProfile({
            ...userData,
            photoURL: userData.photoURL || currentUser.photoURL // Use Google photo if no custom photo
          });
        }
      }
    };

    const fetchUserEvents = async () => {
      if (auth.currentUser) {
        const userRef = doc(db, 'users', auth.currentUser.uid);
        const userDoc = await getDoc(userRef);
        const userData = userDoc.data();

        const eventsWithDetails = await Promise.all(
          (userData.registeredEvents || []).map(async (event) => {
            const eventRef = doc(db, 'events', event.eventId);
            const eventDoc = await getDoc(eventRef);
            return { 
              ...event, 
              ...eventDoc.data(),
              id: eventDoc.id // Ensure the event id is included
            };
          })
        );
        
        setUserEvents(eventsWithDetails);
      };
    };
    fetchUserProfile();
    fetchUserEvents();
  }, [currentUser]);

  const handleEditProfile = () => {
    setIsEditing(true);
  };

  const handleEventClick = (eventId) => {
    navigate(`/event-details/${eventId}`);
  };

  const handleImageUpload = async (event) => {

    if (!currentUser) {
      console.error("No authenticated user");
      alert("Please sign in to upload an image.");
      return;
    }

    const file = event.target.files[0];
    if (file) {
      try {
        const storageRef = ref(storage, `profilePictures/${currentUser.uid}/profile.jpg`);
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        setUserProfile({ ...userProfile, photoURL: downloadURL });
        await updateDoc(doc(db, 'users', currentUser.uid), { photoURL: downloadURL });
      } catch (error) {
        console.error("Error uploading image:", error);
        alert("Failed to upload image. Please try again.");
      }
    }
  };

  const handleSaveProfile = async () => {
    try {
      await updateDoc(doc(db, 'users', currentUser.uid), userProfile);
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      alert("Failed to update profile. Please try again.");
    }
  };


  if (!userProfile) return <Typography>Loading...</Typography>;

  return (
    <DashboardContainer>
      <ProfileSection>
      <AvatarUpload>
          <Avatar
            src={userProfile.photoURL || '/path/to/default/avatar.png'}
            alt={userProfile.name}
            sx={{ width: 120, height: 120 }}
          />
          <AvatarOverlay className="overlay">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <input hidden accept="image/*" type="file" onChange={handleImageUpload} />
              <CameraAltIcon sx={{ color: 'white', fontSize: 40 }} />
            </IconButton>
          </AvatarOverlay>
        </AvatarUpload>
        <Typography variant="h5">{userProfile.name}</Typography>
        <Typography variant="body2" color="textSecondary">{userProfile.email}</Typography>
        <SimpleButton
          startIcon={<EditIcon />}
          onClick={() => setIsEditing(!isEditing)}
        >
          {isEditing ? 'Cancel Edit' : 'Edit Profile'}
        </SimpleButton>
      </ProfileSection>

      {isEditing && (
        <Paper sx={{ p: 2, mb: 3 }}>
          <Typography variant="h6" gutterBottom>Edit Profile</Typography>
          <TextField
            fullWidth
            label="Name"
            value={userProfile.name}
            onChange={(e) => setUserProfile({...userProfile, name: e.target.value})}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Grade"
            value={userProfile.grade || ''}
            onChange={(e) => setUserProfile({...userProfile, grade: e.target.value})}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Phone Number"
            value={userProfile.phone || ''}
            onChange={(e) => setUserProfile({...userProfile, phone: e.target.value})}
            margin="normal"
          />
          <input
            accept="image/*"
            type="file"
            onChange={handleImageUpload}
            style={{ display: 'none' }}
            id="raised-button-file"
          />

          <SimpleButton
            startIcon={<SaveIcon />}
            onClick={handleSaveProfile}
            fullWidth
          >
            Save Profile
          </SimpleButton>

        </Paper>
      )}

      <HistorySection>
        <Box display="flex" alignItems="center" mb={2}>
          <HistoryIcon sx={{ mr: 1 }} />
          <Typography variant="h6">Event History</Typography>
        </Box>

      <Grid container spacing={3}>
        {userEvents.map((event) => (
          <Grid item xs={12} sm={6} md={4} key={event.id}>
            <Card>
              <CardActionArea onClick={() => handleEventClick(event.id)}>
                <CardMedia
                  component="img"
                  height="140"
                  image={event.imageUrl}
                  alt={event.name}
                />
                <CardContent>
                  <Typography variant="h6">{event.name}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    Registered on: {new Date(event.registrationDate).toLocaleDateString()}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
      
      {userEvents.length === 0 && (
        <Typography>
          Let's have some fun!
          <Button color="primary" variant='contained' href="/events">
            EXPLORE
          </Button>
        </Typography>
        )}
      </HistorySection>
    </DashboardContainer>
  );
}

export default UserDashboard;