// src/hooks/useNavigationWarning.js
import { useEffect, useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export function useNavigationWarning(shouldWarn) {
  const [showWarning, setShowWarning] = useState(false);
  const [pendingPath, setPendingPath] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const handleBeforeUnload = useCallback((event) => {
    if (shouldWarn) {
      event.preventDefault();
      return (event.returnValue = '');
    }
  }, [shouldWarn]);

  useEffect(() => {
    if (shouldWarn) {
      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }
  }, [shouldWarn, handleBeforeUnload]);

  const handleNavigationAttempt = (path) => {
    if (shouldWarn) {
      setShowWarning(true);
      setPendingPath(path);
      return false;
    }
    return true;
  };

  const confirmNavigation = () => {
    setShowWarning(false);
    navigate(pendingPath);
  };

  const cancelNavigation = () => {
    setShowWarning(false);
    setPendingPath('');
  };

  return {
    showWarning,
    handleNavigationAttempt,
    confirmNavigation,
    cancelNavigation
  };
}
