import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { 
  Container, 
  Typography, 
  Button, 
  Box, 
  Paper, 
  TextField,
  Checkbox,
  FormControlLabel,
  Collapse,
  Alert,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { addDoc, collection, doc, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { auth, db, storage } from '../firebase';
import { generateSlug } from '../utils/slugGenerator';
import { useDropzone } from 'react-dropzone';
import dayjs from 'dayjs';
import { useAdminPaymentDetails } from '../hooks/useAdminPaymentDetails';


function RegisterEvent() {
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [error, setError] = useState('');
  const [useSavedPaymentDetails, setUseSavedPaymentDetails] = useState(false);

  
 // Basic event details
 const [event, setEvent] = useState({
  name: '',
  startDate: '',
  endDate: '',
  time: '', // Added time field
  organizer: '', // Added organizer field
  isMultiDay: false,
  place: '',
  price: '0',
  tickets: '',
  description: '',
});

const { currentUser } = useAuth();


// Ticket types state
const [ticketTypes, setTicketTypes] = useState([
  { name: 'Regular', price: '0', quantity: '0', status: 'Available' }
]);

  // Payment details state
  const [paymentDetails, setPaymentDetails] = useState({
    accountNumber: '',
    accountHolderName: '',
    ifscCode: '',
    bankName: '',
    upiId: ''
  });

  const { paymentDetails: savedPaymentDetails, loading: paymentDetailsLoading } = useAdminPaymentDetails();

    // Ticket type handlers
    const handleTicketTypeChange = (index, field, value) => {
      const newTicketTypes = [...ticketTypes];
      newTicketTypes[index][field] = value;
      setTicketTypes(newTicketTypes);
    };
  
    const addTicketType = () => {
      setTicketTypes([...ticketTypes, { 
        name: '', 
        price: '0', 
        quantity: '0', 
        status: 'Available' 
      }]);
    };
  
    const removeTicketType = (index) => {
      if (ticketTypes.length > 1) {
        setTicketTypes(ticketTypes.filter((_, i) => i !== index));
      }
    };


  useEffect(() => {
    if (savedPaymentDetails && useSavedPaymentDetails) {
      setPaymentDetails(savedPaymentDetails);
    }
  }, [savedPaymentDetails, useSavedPaymentDetails]);

  // Check if user is admin
  useEffect(() => {
    const checkAdminStatus = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          setError('You must be logged in');
          setLoading(false);
          return;
        }

        const adminDoc = await getDoc(doc(db, 'admins', user.uid));
        if (!adminDoc.exists()) {
          setError('You do not have administrator permissions');
          setLoading(false);
          return;
        }

        setIsAdmin(true);
        setLoading(false);
      } catch (error) {
        console.error('Error checking admin status:', error);
        setError('Error verifying permissions');
        setLoading(false);
      }
    };

    checkAdminStatus();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEvent(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handlePaymentDetailsChange = (e) => {
    const { name, value } = e.target;

        // Special handling for account number to ensure only numbers
        if (name === 'accountNumber' && !/^\d*$/.test(value)) {
          return;
        }

    setPaymentDetails(prev => ({
      ...prev,
      [name]: value
    }));
    // If they're changing values, they're not using saved details anymore
    if (value !== savedPaymentDetails?.[name]) {
      setUseSavedPaymentDetails(false);
    }
  };


  const validateImage = (file) => {
    if (!file) return;
    
    // Check file size (5MB limit)
    if (file.size > 5 * 1024 * 1024) {
      throw new Error('Image must be smaller than 5MB');
    }
  
    // Check file type
    if (!file.type.match('image.*')) {
      throw new Error('File must be an image');
    }
  };


  const handleImageUpload = async (image) => {
    if (!image) return null;
    
    try {
      // Validate file size
      if (image.size > 5 * 1024 * 1024) {
        throw new Error('Image must be smaller than 5MB');
      }
  
      // Validate file type
      if (!image.type.match('image.*')) {
        throw new Error('File must be an image');
      }
  
      const timestamp = Date.now();
      const fileName = `${timestamp}_${image.name.replace(/[^a-zA-Z0-9._]/g, '_')}`;
      const imageRef = ref(storage, `images/${fileName}`);
  
      // Add metadata
      const metadata = {
        contentType: image.type,
        customMetadata: {
          uploadedBy: auth.currentUser.uid,
          uploadedAt: timestamp.toString()
        }
      };
  
      await uploadBytes(imageRef, image, metadata);
      const url = await getDownloadURL(imageRef);
      return url;
  
    } catch (error) {
      console.error('Image upload error:', error);
      throw new Error(`Failed to upload image: ${error.message}`);
    }
  };


  // const onDrop = useCallback((acceptedFiles) => {
  //   const file = acceptedFiles[0];
  //   try {
  //     validateImage(file);
  //     setImage(file);
  //     setImagePreview(URL.createObjectURL(file));
  //   } catch (error) {
  //     setError(error.message);
  //   }
  // }, []);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.gif']
    },
    multiple: false
  });


  const verifyAuthState = async () => {
    try {
      // Force token refresh
      await auth.currentUser?.getIdToken(true);
      
      const token = await auth.currentUser?.getIdToken();
      console.log("Auth Token:", token ? "Present" : "Missing");
      
      const adminDoc = await getDoc(doc(db, 'admins', auth.currentUser?.uid));
      console.log("Admin Status:", adminDoc.exists());
      
      return true;
    } catch (error) {
      console.error("Auth Verification Failed:", error);
      return false;
    }
  };


  const PaymentDetailsSection = () => (
    <Collapse in={parseFloat(event.price) > 0}>
      <Box sx={{ mt: 3, mb: 2 }}>
        <Typography variant="h6" gutterBottom>
          Payment Details
        </Typography>

        {savedPaymentDetails && (
          <FormControlLabel
            control={
              <Checkbox
                checked={useSavedPaymentDetails}
                onChange={(e) => {
                  setUseSavedPaymentDetails(e.target.checked);
                  if (e.target.checked && savedPaymentDetails) {
                    setPaymentDetails(savedPaymentDetails);
                  } else {
                    setPaymentDetails({
                      accountNumber: '',
                      accountHolderName: '',
                      ifscCode: '',
                      bankName: '',
                      upiId: ''
                    });
                  }
                }}
              />
            }
            label="Use saved payment details"
          />
        )}

      {(!useSavedPaymentDetails || !savedPaymentDetails) && (
          <>
            <TextField
              name="accountHolderName"
              label="Account Holder Name"
              value={paymentDetails.accountHolderName}
              onChange={handlePaymentDetailsChange}
              fullWidth
              margin="normal"
              required={parseFloat(event.price) > 0}
              autoComplete="off"
            />
            
            <TextField
              name="accountNumber"
              label="Account Number"
              value={paymentDetails.accountNumber}
              onChange={handlePaymentDetailsChange}
              fullWidth
              margin="normal"
              required={parseFloat(event.price) > 0}
              type="number"
              autoComplete="off"
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            />
            
            <TextField
              name="ifscCode"
              label="IFSC Code"
              value={paymentDetails.ifscCode}
              onChange={handlePaymentDetailsChange}
              fullWidth
              margin="normal"
              required={parseFloat(event.price) > 0}
              autoComplete="off"
            />
            
            <TextField
              name="bankName"
              label="Bank Name"
              value={paymentDetails.bankName}
              onChange={handlePaymentDetailsChange}
              fullWidth
              margin="normal"
              required={parseFloat(event.price) > 0}
              autoComplete="off"
            />
            
            <TextField
              name="upiId"
              label="UPI ID (Optional)"
              value={paymentDetails.upiId}
              onChange={handlePaymentDetailsChange}
              fullWidth
              margin="normal"
              autoComplete="off"
            />
          </>
        )}

        {useSavedPaymentDetails && savedPaymentDetails && (
          <Box sx={{ mt: 2, p: 2, bgcolor: 'grey.50', borderRadius: 1 }}>
            <Typography variant="subtitle2" gutterBottom>
              Using saved payment details:
            </Typography>
            <Typography variant="body2">
              Account Holder: {savedPaymentDetails.accountHolderName}
            </Typography>
            <Typography variant="body2">
              Bank: {savedPaymentDetails.bankName}
            </Typography>
            <Typography variant="body2">
              Account: ****{savedPaymentDetails.accountNumber.slice(-4)}
            </Typography>
          </Box>
        )}
      </Box>
    </Collapse>
  );

  const testAdminAccess = async () => {
    try {
      const user = auth.currentUser;
      if (!user) {
        console.error("No user logged in");
        return;
      }
  
      // Test Firestore access
      const adminDoc = await getDoc(doc(db, 'admins', user.uid));
      console.log("Firestore Admin Check:", {
        exists: adminDoc.exists(),
        data: adminDoc.data()
      });
  
      // Test Storage access
      const testRef = ref(storage, 'images/test.txt');
      const testBlob = new Blob(['test'], { type: 'text/plain' });
      try {
        await uploadBytes(testRef, testBlob);
        console.log("Storage write successful");
      } catch (storageError) {
        console.error("Storage write failed:", storageError);
      }
    } catch (error) {
      console.error("Admin access test failed:", error);
    }
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    
    try {

      const user = auth.currentUser;
      if (!user) {
        throw new Error("Not authenticated");
      }

  // Verify admin status first
    const adminDoc = await getDoc(doc(db, 'admins', auth.currentUser.uid));
    if (!adminDoc.exists()) {
      throw new Error("Admin Accesss Required");
    }

      // Validate end date for multi-day events
      if (event.isMultiDay && dayjs(event.endDate).isBefore(dayjs(event.startDate))) {
        throw new Error("End date must be after start date");
      }

      let imageUrl = '';
      if (image) {
        imageUrl = await handleImageUpload(image);
      }
    

      const eventData = {
        name: event.name,
        startDate: dayjs(event.startDate).format('DD/MM/YYYY'),
        endDate: event.isMultiDay ? dayjs(event.endDate).format('DD/MM/YYYY') : null,
        time: event.time,
        organizer: event.organizer,
        isMultiDay: event.isMultiDay,
        place: event.place,
        description: event.description,
        imageUrl,
        slug: generateSlug(event.name),
        ticketTypes, // Add the ticket types array
        adminId: currentUser.uid,
        registrations: 0,
        createdBy: currentUser.uid,
        createdAt: dayjs().toISOString(),
        paymentDetails: parseFloat(event.price) > 0 ? paymentDetails : null,
      };

      await addDoc(collection(db, 'events'), eventData);
      alert('Event registered successfully!');
      navigate('/admin-dashboard');
    } catch (error) {
      console.error("Error:", error);
      setError(error.message || 'Failed to register event');
      alert(`Failed to create event: ${error.message}`);
    }
  };



  if (loading) {
    return (
      <Container maxWidth="sm">
        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Typography>Loading...</Typography>
        </Box>
      </Container>
    );
  }

  if (!isAdmin) {
    return (
      <Container maxWidth="sm">
        <Box sx={{ mt: 4 }}>
          <Alert severity="error">
            {error || "You don't have permission to access this page"}
          </Alert>
        </Box>
      </Container>
    );
  }


  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: { xs: 2, md: 4 }, mt: 4 }}>
        <Typography variant="h5" gutterBottom>Register New Event</Typography>
        
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
        )}

        <form onSubmit={handleSubmit}>


        {/* <Button onClick={testAdminAccess} type="button">
          Test Admin Access
        </Button> */}


        {/* <Button 
            onClick={async () => {
              const isVerified = await verifyAuthState();
              alert(isVerified ? "Auth OK" : "Auth Failed");
            }}
            type="button"
          >
            Verify Auth
          </Button> */}

          <TextField
            name="name"
            label="Event Name"
            value={event.name}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          
          <TextField
            name="startDate"
            label="Start Date"
            type="date"
            value={event.startDate}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            inputProps={{ min: dayjs().format('YYYY-MM-DD') }}
            required
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={event.isMultiDay}
                onChange={handleChange}
                name="isMultiDay"
              />
            }
            label="Multi-day event"
          />

          {event.isMultiDay && (
            <TextField
              name="endDate"
              label="End Date"
              type="date"
              value={event.endDate}
              onChange={handleChange}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
              inputProps={{ min: event.startDate }}
              required
            />
          )}

          <TextField
            name="place"
            label="Event Place"
            value={event.place}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />

          <TextField
            fullWidth
            margin="normal"
            name="time"
            label="Event Time"
            type="time"
            value={event.time}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            required
          />


          <TextField
            name="price"
            label="Price (Enter 0 for free event)"
            value={event.price}
            onChange={handleChange}
            fullWidth
            margin="normal"
            type="number"
            required
          />


          <PaymentDetailsSection />

          <TextField
            name="tickets"
            label="Number of Tickets"
            value={event.tickets}
            onChange={handleChange}
            fullWidth
            margin="normal"
            type="number"
            required
          />


          {/* Ticket Types Section */}
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" gutterBottom>
              Ticket Types
            </Typography>
            {ticketTypes.map((ticket, index) => (
              <Box key={index} sx={{ mb: 2, p: 2, border: '1px solid #ddd', borderRadius: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Ticket Name"
                      value={ticket.name}
                      onChange={(e) => handleTicketTypeChange(index, 'name', e.target.value)}
                      placeholder="Early Bird/Regular/VIP"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Price"
                      type="number"
                      value={ticket.price}
                      onChange={(e) => handleTicketTypeChange(index, 'price', e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Quantity"
                      type="number"
                      value={ticket.quantity}
                      onChange={(e) => handleTicketTypeChange(index, 'quantity', e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel>Status</InputLabel>
                      <Select
                        value={ticket.status}
                        onChange={(e) => handleTicketTypeChange(index, 'status', e.target.value)}
                        label="Status"
                      >
                        <MenuItem value="Available">Available</MenuItem>
                        <MenuItem value="Coming Soon">Coming Soon</MenuItem>
                        <MenuItem value="Sold Out">Sold Out</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                {ticketTypes.length > 1 && (
                  <IconButton 
                    onClick={() => removeTicketType(index)}
                    color="error"
                    sx={{ mt: 1 }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
            ))}
            <Button 
              onClick={addTicketType}
              startIcon={<AddIcon />}
              variant="outlined"
              sx={{ mt: 1 }}
            >
              Add Ticket Type
            </Button>
          </Box>



          <TextField
            name="description"
            label="Add Description"
            value={event.description}
            onChange={handleChange}
            fullWidth
            margin="normal"
            multiline
            rows={4}
            required
          />

          <Box 
            {...getRootProps()} 
            sx={{
              border: '2px dashed grey',
              borderRadius: 2,
              p: 2,
              mt: 2,
              mb: 2,
              textAlign: 'center',
              cursor: 'pointer'
            }}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography>Drop the image here ...</Typography>
            ) : (
              <Typography>Drag 'n' drop an event image here, or click to select files</Typography>
            )}
          </Box>

          {imagePreview && (
            <Box sx={{ mt: 2, mb: 2 }}>
              <img 
                src={imagePreview} 
                alt="Event preview" 
                style={{ 
                  maxWidth: '100%', 
                  maxHeight: '200px',
                  borderRadius: '8px'
                }} 
              />
            </Box>
          )}

          <TextField
            fullWidth
            margin="normal"
            name="organizer"
            label="Event Organizer"
            value={event.organizer}
            onChange={handleChange}
            required
          />

          <Button 
            type="submit"
            variant="contained" 
            fullWidth
            sx={{ 
              mt: 3,
              mb: 2,
              py: 1.5
            }}
          >
            Register Event
          </Button>
        </form>
      </Paper>
    </Container>
  );
}

export default RegisterEvent;



