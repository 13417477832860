import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FF0000', // Red color for navbar
    },
    secondary: {
      main: '#FFFFFF', // White color for menu
    },
    background: {
      default: '#FFFFFF', // White background
      paper: '#F5F5F5', // Light gray for certain sections
    },
  },
  typography: {
    fontFamily: '"Roboto", sans-serif'
  },

});

export default theme;