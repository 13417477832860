import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Box, IconButton, useMediaQuery, useTheme, Typography} from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import logo_3 from '../assets/images/logo_3.png';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter'; // Add this import



const ResponsiveLogo = styled('img')(({ theme }) => ({
  height: '40px',
  [theme.breakpoints.up('sm')]: {
    height: '50px',
  },
  [theme.breakpoints.up('md')]: {
    height: '60px',
  },
}));

const MenuText = styled(Typography)({
  fontFamily: '"Tanker", sans-serif',
  fontSize: '40px',
  textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
  color: 'white',
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    width: '0%',
    height: '4px',
    bottom: '-5px',
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: '#e94560',
    transition: 'width 0.3s ease-in-out',
  },
  '&:hover::after': {
    width: '100%',
  },
});

const NeonIcon = styled(IconButton)({
  color: 'white',
  fontSize: '60px',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    color: '#e94560',
    textShadow: '0 0 10px #e94560, 0 0 20px #e94560, 0 0 30px #e94560',
  }
});

const MenuToggle = styled('div')(({ open }) => ({
  width: 30,
  height: 25,
  position: 'relative',
  transform: open ? 'rotate(45deg)' : 'none',
  transition: 'transform 0.3s ease-in-out',
  '& > span': {
    display: 'block',
    position: 'absolute',
    height: 3,
    width: '100%',
    background: '#fff',
    borderRadius: 3,
    opacity: 1,
    left: 0,
    transform: open ? 'rotate(90deg)' : 'none',
    transition: 'transform 0.3s ease-in-out',
    '&:nth-of-type(1)': {
      top: open ? '50%' : 0,
    },
    '&:nth-of-type(2)': {
      top: '50%',
      transform: open ? 'rotate(0deg)' : 'none',
    },
    '&:nth-of-type(3)': {
      top: open ? '50%' : '100%',
      transform: open ? 'rotate(90deg)' : 'none',
    },
  },
}));

  

function LogoWithLink() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogoClick = (e) => {
    e.preventDefault();
    
    // Check if we're in the middle of creating/editing an event
    const isEventForm = location.pathname === '/register-event' || 
                       location.pathname.startsWith('/edit-event');
                       
    if (isEventForm) {
      if (window.confirm('Are you sure you want to leave? Any unsaved changes will be lost.')) {
        navigate(currentUser?.isAdmin ? '/admin-dashboard' : '/');
      }
      return;
    }

    // If not logged in, always go to landing page
    if (!currentUser) {
      navigate('/');
      return;
    }

    // If admin, go to admin dashboard
    if (currentUser.isAdmin) {
      navigate('/admin-dashboard');
      return;
    }

    // Regular user, go to home
    navigate('/home');
  };

  return (
    <Box onClick={handleLogoClick} sx={{ cursor: 'pointer' }}>
      <ResponsiveLogo src={logo_3} alt="SNEAK IN" />
    </Box>
  );
}

const FullScreenMenu = styled(Box)(({ theme, open }) => ({
  position: 'fixed',
  top: '64px', // Match the AppBar height exactly
  left: 0,
  right: open ? 0 : '-100%',
  width: '100%',
  height: 'calc(100vh - 64px)', // Subtract the exact AppBar height
  background: 'linear-gradient(45deg, #1a1a2e 0%, #16213e 50%, #0f3460 100%)',
  transition: 'right 0.3s ease-in-out',
  zIndex: theme.zIndex.drawer + 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between', // This will push content to top and bottom
  alignItems: 'center',
  padding: '20px 0', // Add some vertical padding
  overflow: 'hidden',
  pointerEvents: open ? 'auto' : 'none',  // Disable interaction when closed
  visibility: open ? 'visible' : 'hidden',  // Hide when closed
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'radial-gradient(circle, transparent 20%, #16213e 20%, #16213e 80%, transparent 80%, transparent), radial-gradient(circle, transparent 20%, #16213e 20%, #16213e 80%, transparent 80%, transparent) 50px 50px, linear-gradient(#0f3460 8px, transparent 8px) 0 -4px, linear-gradient(90deg, #0f3460 8px, transparent 8px) -4px 0',
    backgroundSize: '100px 100px, 100px 100px, 50px 50px, 50px 50px',
    opacity: 0.1,
  }
}));

function Layout({ children }) {
  const theme = useTheme(); // Add this hook
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);


  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error("Failed to log out", error);
    }
  };

  const getNavItems = () => {
    if (!currentUser) {
      return [
        { label: 'LOG IN', path: '/login' },
        { label: 'EVENTS', path: '/events' },
        { label: 'REGISTER EVENT', requiresAdmin: true },
        { label: 'CONTACT', path: '/contact' },
      ];
    }
    
    if (currentUser.isAdmin) {
      return [
        { label: 'MY EVENTS', path: '/admin-dashboard' },
        { label: 'ADD NEW EVENT', path: '/register-event' },
        { label: 'LOG OUT', action: handleLogout },
      ];
    }
    
    return [
      { label: 'HOME', path: '/home' },
      { label: 'EVENTS', path: '/events' },
      { label: 'PROFILE', path: '/dashboard' },
      { label: 'LOG OUT', action: handleLogout },
    ];
  };

  const navItems = getNavItems();

//   // const handleNavigation = (item) => {
//   //   if (drawerOpen) {
//   //     setDrawerOpen(false);
//   //   }
    
// // In Layout.js, update the handleMenuClick function
// const handleMenuClick = async (item) => {
//   try {
//     // Handle logout action
//     if (item.action) {
//       await item.action();
//       setDrawerOpen(false);
//       return;
//     }

//     // Handle admin routes
//     if (item.isAdmin) {
//       // Check if user is already logged in as admin
//       if (currentUser?.isAdmin) {
//         navigate('/register-event');
//       } else {
//         navigate('/admin-auth');
//       }
//       setDrawerOpen(false);
//       return;
//     }

//     // Handle normal navigation
//     navigate(item.path);
//     setDrawerOpen(false);
//   } catch (error) {
//     console.error('Navigation error:', error);
//   }
// };

// // Update getNavItems function
// const getNavItems = () => {
//   if (!currentUser) {
//     return [
//       { label: 'LOG IN', path: '/login' },
//       { label: 'EVENTS', path: '/events' },
//       { label: 'REGISTER EVENT', isAdmin: true }, // Remove path, keep isAdmin flag
//       { label: 'CONTACT', path: '/contact' },
//     ];
//   }
  
//   // If user is admin
//   if (currentUser.isAdmin) {
//     return [
//       { label: 'MY EVENTS', path: '/admin-dashboard' },
//       { label: 'ADD NEW EVENT', path: '/register-event' },
//       { label: 'LOG OUT', action: handleLogout },
//     ];
//   }
  
//   // Regular logged-in user
//   return [
//     { label: 'HOME', path: '/home' },
//     { label: 'EVENTS', path: '/events' },
//     { label: 'PROFILE', path: '/dashboard' },
//     { label: 'LOG OUT', action: handleLogout },
//   ];
// };  


// In Layout.js

const handleMenuClick = async (item) => {
  try {
    // Close menu first
    setDrawerOpen(false);

    // Handle logout
    if (item.action) {
      await item.action();
      return;
    }

    // Handle admin routes
    if (item.requiresAdmin) {
      if (currentUser?.isAdmin) {
        navigate('/register-event');
      } else {
        navigate('/admin-auth');
      }
      return;
    }

    // Normal navigation
    navigate(item.path);
  } catch (error) {
    console.error('Navigation error:', error);
    alert('Navigation failed. Please try again.');
  }
};

// Update your menu items rendering
{navItems.map((item) => (
  <Box
    key={item.label}
    onClick={() => handleMenuClick(item)}
    sx={{
      cursor: 'pointer',
      mb: 2
    }}
  >
    <MenuText>
      {item.label}
    </MenuText>
  </Box>
))}


useEffect(() => {
  setDrawerOpen(false);
}, [location.pathname]);


  return (
    <>
      <AppBar position="fixed" sx={{
         zIndex: theme.zIndex.drawer + 2,
         boxshadow: 'none',
         borderBottom: 'none',
         height: '64px' // Set a fixed height
          }}>
        <Toolbar sx={{
            minHeight: '64px !important', 
            padding: '0 !important'
           }} >
          <Box sx={{ flexGrow: 1}}>
            <LogoWithLink />
          </Box>

          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ ml: 2 }}
            onClick={() => setDrawerOpen(!drawerOpen)}
          >
            <MenuToggle open={drawerOpen}>
              <span />
              <span />
              <span />
            </MenuToggle>
          </IconButton>
        </Toolbar>
      </AppBar>
      
      <FullScreenMenu open={drawerOpen}>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              justifyContent: 'center',
              flexGrow: 1 // This will make it take up all available space
            }}>
              {/* {navItems.map((item) => (
                <MenuText 
                  component={item.path ? Link : 'div'}
                  to={item.path}
                  onClick={() => {
                    setDrawerOpen(false);
                    if (item.action) {
                      item.action();
                    } else if (item.path) {
                      navigate(item.path);  // Use navigate for programmatic navigation
                    }
                  }}
                  key={item.label}
                  sx={{
                    mb: 2,
                    textDecoration: 'none',
                    cursor: 'pointer',
                    '&:hover': {
                      opacity: 0.8,
                    },
                  }}
                >
                  {item.label}
                </MenuText>
              ))} */}
                {navItems.map((item) => (
                  <Box
                    key={item.label}
                    onClick={() => handleMenuClick(item)}
                    sx={{
                      cursor: 'pointer',
                      mb: 2
                    }}
                  >
                    <MenuText>
                      {item.label}
                    </MenuText>
                  </Box>
                ))}
            </Box>

            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'center', 
              width: '100%',
              mt: 'auto' // This will push it to the bottom
            }}>
              <NeonIcon onClick={() => window.open('https://instagram.com/sneak.in_', '_blank')}>
                <InstagramIcon fontSize="inherit" />
              </NeonIcon>
              <NeonIcon onClick={() => window.open('https://x.com/sneak_in_', '_blank')}>
                <TwitterIcon fontSize="inherit" />
              </NeonIcon>
            </Box>
          </FullScreenMenu>

      <Box component="main" sx={{ 
          paddingTop: '64px', // Exact height of AppBar
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          zIndex: theme.zIndex.drawer - 1  // Ensure it's below the menu
        }}>{children}</Box>
    </>
  );
}

export default Layout;