// src/hooks/useAdminPaymentDetails.js
import { useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';

export const useAdminPaymentDetails = () => {
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchPaymentDetails = async () => {
    setLoading(true);
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('No authenticated user');
      }

      const adminDoc = await getDoc(doc(db, 'admins', user.uid));
      if (adminDoc.exists() && adminDoc.data().paymentDetails) {
        setPaymentDetails(adminDoc.data().paymentDetails);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPaymentDetails();
  }, []);

  const savePaymentDetails = async (details) => {
    try {
      const user = auth.currentUser;
      if (!user) throw new Error('No authenticated user');

      await setDoc(doc(db, 'admins', user.uid), {
        paymentDetails: details
      }, { merge: true });

      setPaymentDetails(details);
      return true;
    } catch (err) {
      setError(err.message);
      return false;
    }
  };

  return { 
    paymentDetails, 
    loading, 
    error, 
    savePaymentDetails,
    refreshPaymentDetails: fetchPaymentDetails 
  };
};